import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Stack , Grid} from '@mui/material';
import CardGraphics from '../../../../components/card/CardGraphics';
import Column from '../../../../components/graphics/Column';
import ColumnGroup from '../../../../components/graphics/ColumnGroup';
import ColumnStacked from '../../../../components/graphics/ColumnStacked';
import MultiLine from '../../../../components/graphics/MultiLine';
import { requestPost } from '../../../../../utils/request';
import { metricsExt, formatMessage, objectAssignExt } from '../../../../../utils/utils';
import { constants, URL_API_EXT, URL_API_INV } from '../../../../../constants';

function Activities(props) {
    const { extension, settings, setExtension, setSettings } = props;

    const { t } = useTranslation();

    const handleOnChange = (value, state) => {
        const newExtension = objectAssignExt(extension, {
            metrics: { activities: { [state]: value } },
        });
        setExtension(
            metricsExt(
                [state],
                extension.metrics.activities.source,
                extension.hierarchy,
                newExtension
            )
        );
    };
    useEffect(() => {
        setSettings({ backdrop: formatMessage(true, '', t('dependence.extension.consultingInfoOpportunities')) });
        if (extension.hierarchy.stepKey) {
            const hierarchy = {
                ...extension.hierarchy,
                resource: 'Actividades',
            };
            Promise.all([
                requestPost(`${URL_API_EXT}/extension/activities`, { search: extension.search.search }),
                requestPost(`${URL_API_INV}/usability/resource`, { record: hierarchy }),
            ])
                .then(res => {
                    setExtension(
                        metricsExt(
                            Object.keys(extension.metrics.activities),
                            res[0].data,
                            hierarchy,
                            extension
                        )
                    );
                    setSettings({ backdrop: formatMessage(false, '', '') });
                })
                .catch(err => {
                    setSettings({
                        backdrop: formatMessage(false, '', ''),
                        snackbar: formatMessage(true, 'error', err),
                    });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [extension.hierarchy.stepKey, extension.search.search]);
    return (
        <Box>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <CardGraphics
                        title={t('dependence.extension.activitiesMain.numberActivities')}
                        tools={{
                            usability: true,
                            rangeTime: true,
                            gender: false,
                            type: false,
                            accumulate: true,
                            wordCloud: false,
                            download: true,
                            dialog: false,
                            dataTable: true
                        }}
                        infoDialog={{
                            label: t('dependence.extension.activitiesMain.numberActivitiesInfo'),
                            gif: '',
                            notice: t('dependence.extension.activitiesMain.numberActivitiesNotice')
                        }}
                        infoChart={{
                            data: extension.metrics.activities[constants.METRIC_COUNT_FIELD],
                            onChange: (value) => handleOnChange(value, constants.METRIC_COUNT_FIELD),
                            columns: {
                                axis: extension.filters.intervalTime.applied.value[settings.language === 'es' ? 1 : 2],
                                value: t('dependence.extension.activitiesMain.numberActivitiesGraphValue')
                            },
                            formatValue: 'number',
                            sortField: 'axis'
                        }}
                    >
                        <Column
                            infoChart={{
                                data: extension.metrics.activities[constants.METRIC_COUNT_FIELD].processed,
                                colorGender: false,
                                axis: {
                                    xAxis: extension.filters.intervalTime.applied.value[settings.language === 'es' ? 1 : 2],
                                    yAxis: t('dependence.extension.activitiesMain.numberActivitiesGraphAxisY')
                                },
                                formatValue: 'number',
                                label: false
                            }}
                        />
                    </CardGraphics>
                </Grid>
                <Grid item xs={12} md={6}>
                    <CardGraphics
                        title={t('dependence.extension.activitiesMain.numberParticipants')}
                        tools={{
                            usability: true,
                            rangeTime: true,
                            gender: false,
                            type: false,
                            accumulate: true,
                            wordCloud: false,
                            download: true,
                            dialog: false,
                            dataTable: true
                        }}
                        infoDialog={{
                            label: t('dependence.extension.activitiesMain.numberParticipantsInfo'),
                            gif: '',
                            notice: t('dependence.extension.activitiesMain.numberParticipantsNotice')
                        }}
                        infoChart={{
                            data: extension.metrics.activities[constants.METRIC_ENROLLED_FIELD],
                            onChange: (value) => handleOnChange(value, constants.METRIC_ENROLLED_FIELD),
                            columns: {
                                axis: extension.filters.intervalTime.applied.value[settings.language === 'es' ? 1 : 2],
                                value: t('dependence.extension.activitiesMain.numberParticipantsGraphValue')
                            },
                            formatValue: 'number',
                            sortField: 'axis'
                        }}
                    >
                        <Column
                            infoChart={{
                                data: extension.metrics.activities[constants.METRIC_ENROLLED_FIELD].processed,
                                colorGender: false,
                                axis: {
                                    xAxis: extension.filters.intervalTime.applied.value[settings.language === 'es' ? 1 : 2],
                                    yAxis: t('dependence.extension.activitiesMain.numberParticipantsGraphAxisY')
                                },
                                formatValue: 'number',
                                label: false
                            }}
                        />
                    </CardGraphics>
                </Grid>
            </Grid>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} sx={{ mt: 3 }}>
                <CardGraphics
                    title={t('dependence.extension.activitiesMain.numberTopology')}
                    tools={{
                        usability: true,
                        rangeTime: true,
                        gender: false,
                        type: false,
                        accumulate: true,
                        wordCloud: false,
                        download: true,
                        dialog: false,
                        dataTable: true
                    }}
                    infoDialog={{
                        label: t('dependence.extension.activitiesMain.numberTopologyInfo'),
                        gif: '',
                        notice: t('dependence.extension.activitiesMain.numberTopologyNotice')
                    }}
                    infoChart={{
                        data: extension.metrics.activities[constants.METRIC_TYPE_COUNT_FIELD],
                        onChange: (value) => handleOnChange(value, constants.METRIC_TYPE_COUNT_FIELD),
                        columns: {
                            group: extension.filters.intervalTime.applied.value[settings.language === 'es' ? 1 : 2],
                            type: t('dependence.extension.activitiesMain.numberTopologyType'),
                            value: t('dependence.extension.activitiesMain.numberTopologyValue')
                        },
                        formatValue: 'number',
                        sortField: 'group'
                    }}
                >
                    <ColumnGroup
                        infoChart={{
                            data: extension.metrics.activities[constants.METRIC_TYPE_COUNT_FIELD].processed,
                            colorGender: false,
                            axis: {
                                xAxis: extension.filters.intervalTime.applied.value[settings.language === 'es' ? 1 : 2],
                                yAxis: t('dependence.extension.activitiesMain.numberTopologyGraphAxisY')
                            }
                        }}
                    />
                </CardGraphics>
            </Stack>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} sx={{ mt: 3 }}>
                <CardGraphics
                    title={t('dependence.extension.activitiesMain.hourlyIntensity')}
                    tools={{
                        usability: true,
                        rangeTime: true,
                        gender: false,
                        type: false,
                        accumulate: false,
                        wordCloud: false,
                        download: true,
                        dialog: false,
                        dataTable: true
                    }}
                    infoDialog={{
                        label: t('dependence.extension.activitiesMain.hourlyIntensityInfo'),
                        gif: '',
                        notice: t('dependence.extension.activitiesMain.hourlyIntensityNotice')
                    }}
                    infoChart={{
                        data: extension.metrics.activities[constants.METRIC_MOD_INTENSITY_FIELD],
                        onChange: (value) => handleOnChange(value, constants.METRIC_MOD_INTENSITY_FIELD),
                        columns: {
                            type: extension.filters.intervalTime.applied.value[settings.language === 'es' ? 1 : 2],
                            value: t('dependence.extension.activitiesMain.hourlyIntensityGraphValue'),
                        },
                        formatValue: 'number',
                        sortField: 'type'
                    }}
                >
                    <MultiLine
                        infoChart={{
                            data: extension.metrics.activities[constants.METRIC_MOD_INTENSITY_FIELD].processed,
                            colorGender: false,
                            axis: {
                                xAxis: extension.filters.intervalTime.applied.value[settings.language === 'es' ? 1 : 2],
                                yAxis: t('dependence.extension.activitiesMain.hourlyIntensityGraphAxisY')
                            },
                        }}
                    />
                </CardGraphics>
            </Stack>
            <Grid container spacing={3} sx={{mt:0.5}}>
                <Grid item xs={12} md={6}>
                    <CardGraphics
                        title={t('dependence.extension.activitiesMain.participantsSubModality')}
                        tools={{
                            usability: true,
                            rangeTime: true,
                            gender: false,
                            type: false,
                            accumulate: true,
                            wordCloud: false,
                            download: true,
                            dialog: false,
                            dataTable: true
                        }}
                        infoDialog={{
                            label: t('dependence.extension.activitiesMain.participantsSubModalityInfo'),
                            gif: '',
                            notice: t('dependence.extension.activitiesMain.participantsSubModalityNotice')
                        }}
                        infoChart={{
                            data: extension.metrics.activities[constants.METRIC_MOD_ENROLLED_FIELD],
                            onChange: (value) => handleOnChange(value, constants.METRIC_MOD_ENROLLED_FIELD),
                            columns: {
                                axis: extension.filters.intervalTime.applied.value[settings.language === 'es' ? 1 : 2],
                                type: t('dependence.extension.activitiesMain.participantsSubModalityType'),
                                value: t('dependence.extension.activitiesMain.participantsSubModalityValue'),
                            },
                            formatValue: 'number',
                            sortField: 'axis'
                        }}
                    >
                        <ColumnStacked
                            infoChart={{
                                data: extension.metrics.activities[constants.METRIC_MOD_ENROLLED_FIELD].processed,
                                colorGender: false,
                                axis: {
                                    xAxis: extension.filters.intervalTime.applied.value[settings.language === 'es' ? 1 : 2],
                                    yAxis: t('dependence.extension.activitiesMain.participantsSubModalityAxisY')
                                },
                            }}
                        />
                    </CardGraphics>
                </Grid>
                <Grid item xs={12} md={6}>
                    <CardGraphics
                        title={t('dependence.extension.activitiesMain.numberSubModality')}
                        tools={{
                            usability: true,
                            rangeTime: true,
                            gender: false,
                            type: false,
                            accumulate: true,
                            wordCloud: false,
                            download: true,
                            dialog: false,
                            dataTable: true
                        }}
                        infoDialog={{
                            label: t('dependence.extension.activitiesMain.numberSubModalityInfo'),
                            gif: '',
                            notice: t('dependence.extension.activitiesMain.numberSubModalityNotice')
                        }}
                        infoChart={{
                            data: extension.metrics.activities[constants.METRIC_MOD_COUNT_FIELD],
                            onChange: (value) => handleOnChange(value, constants.METRIC_MOD_COUNT_FIELD),
                            columns: {
                                axis: extension.filters.intervalTime.applied.value[settings.language === 'es' ? 1 : 2],
                                type: t('dependence.extension.activitiesMain.numberSubModalityType'),
                                value: t('dependence.extension.activitiesMain.numberSubModalityValue')
                            },
                            formatValue: 'number',
                            sortField: 'axis'
                        }}
                    >
                        <ColumnStacked
                            infoChart={{
                                data: extension.metrics.activities[constants.METRIC_MOD_COUNT_FIELD].processed,
                                colorGender: false,
                                axis: {
                                    xAxis: extension.filters.intervalTime.applied.value[settings.language === 'es' ? 1 : 2],
                                    yAxis: t('dependence.extension.activitiesMain.numberSubModalityAxisY')
                                },
                            }}
                        />
                    </CardGraphics>
                </Grid>
            </Grid>
        </Box>
    );
}
const mapStateToProps = (state) => ({
    extension: state.extension,
    settings: state.settings
});
const mapDispatchToProps = (dispatch) => ({
    setExtension(state) {
        dispatch({
            type: 'SET_EXTENSION',
            state,
        });
    },
    setSettings(state) {
        dispatch({
            type: 'SET_SETTINGS',
            state,
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(Activities);