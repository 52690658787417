import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { TabContext, TabPanel } from '@mui/lab';
import { Box, Container, Paper, Stack, Typography } from '@mui/material';
import { GroupTwoTone, GroupsTwoTone, ContentPasteSearchTwoTone, QueryStatsTwoTone, ScreenSearchDesktopTwoTone } from '@mui/icons-material';
import AppBar from '../../../components/appbar/Appbar';
import Widgets from '../../../components/widgets/Widgets';
import InvRangeTime from '../../../components/range/InvRangeTime';
import Products from './panels/Products';
import Researchers from './panels/Researchers';
import Groups from './panels/Groups';
import Projects from './panels/Projects';
import ProductsList from './panels/ProductsList';
import { requestPost } from '../../../../utils/request';
import { metricsInv, formatMessage, getStepResource } from '../../../../utils/utils';
import { constants, URL_API_INV } from '../../../../constants';

function Search(props) {
    const { investigation, settings, setInvestigation, setSettings } = props;
    const [metric, setMetric] = useState('');

    const { t } = useTranslation();
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const widgets = [
        {
            title: t('dependence.investigation.researchers'),
            total: investigation.metrics.general[constants.METRIC_AUTHORS_VALUE_FIELD].processed,
            filter: false,
            icon: < GroupTwoTone />,
            step: 'researchers'
        },
        {
            title: t('dependence.investigation.productsList'),
            total: investigation.metrics.general[constants.METRIC_PUBLICATIONS_LIST_FIELD].processed.length,
            filter: false,
            icon: <ScreenSearchDesktopTwoTone />,
            step: 'productsList'
        },
        {
            title: t('dependence.investigation.products'),
            total: investigation.metrics.general[constants.METRIC_PUBLICATIONS_FIELD].processed + investigation.metrics.general[constants.METRIC_THESIS_FIELD].processed,
            filter: true,
            icon: <ContentPasteSearchTwoTone />,
            step: 'productsSearch',
            subtitle: t('dependence.investigation.citations'),
            sub_total: investigation.metrics.general[constants.METRIC_CITATIONS_FIELD].processed,
        },
        {
            title: t('dependence.investigation.groups'),
            total: investigation.metrics.general[constants.METRIC_GROUPS_FIELD].processed,
            filter: false,
            icon: <GroupsTwoTone />,
            step: 'groupsSearch'
        },
        {
            title: t('dependence.investigation.projects'),
            total: investigation.metrics.general[constants.METRIC_PROJECTS_FIELD].processed,
            filter: true,
            icon: <QueryStatsTwoTone />,
            step: 'projectsSearch'
        }
    ];

    const handleRoute = (resource) => {
        const route = `/search/research/query/${params.query}/resource/${resource}`;
        navigate(route, { state: { key: 'hierarchy' } });
    };

    useEffect(() => {
        getStepResource(params, widgets, navigate, location);
        if (investigation.hierarchy.stepHome) {
            const keyDep = location.state?.key === 'hierarchy' ? false : true;
            window.history.replaceState({}, document.title);
            const hierarchy = {
                ...investigation.hierarchy,
                hierarchy: {
                    ...investigation.hierarchy.hierarchy,
                    dependence: [keyDep, 'Investigación'],
                    hierarchy: ['Búsqueda por tema', params.query]
                },
                params: params,
                resource: 'General'
            };
            let gate = true;
            if (location.state) {
                gate = location.state.gate;
            }
            if (metric !== params.query) {
                setSettings({ backdrop: formatMessage(true, '', t('components.search.SearchTopics.consultingInfoSearch')) });
                setMetric(params.query);
                Promise.all([
                    requestPost(`${URL_API_INV}/investigation/metrics-search`, {
                        gate: gate,
                        value: params.query,
                        record: hierarchy
                    })
                ])
                    .then(res => {
                        setInvestigation({
                            ...metricsInv(
                                Object.keys(investigation.metrics.general),
                                res[0].data,
                                hierarchy,
                                investigation,
                                settings
                            ),
                            search: { search: res[0].data.search }
                        });
                        setSettings({ backdrop: formatMessage(false, '', '') });
                    })
                    .catch(err => {
                        setSettings({
                            backdrop: formatMessage(false, '', ''),
                            snackbar: formatMessage(true, 'error', err),
                        });
                    });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, investigation.hierarchy.stepHome]);
    return (
        <Box sx={{ bgcolor: 'white' }}>
            <AppBar hierarchy='research' params={params} value={params.query}>
                <Typography
                    align='center'
                    sx={{ color: 'white', mb: 1.5 }}
                    variant='h3'
                >
                    {params.query}
                </Typography>
                <Widgets
                    isAuthor={false}
                    widgets={widgets}
                    stepTab={params.resource}
                    handleStepTab={(resource) => handleRoute(resource)}
                />
            </AppBar>
            <Container
                component='main'
                maxWidth='xl'
            >
                <Stack
                    direction='row'
                    justifyContent='right'
                    alignItems='right'
                    sx={{ mt: 1 }}
                >
                    <InvRangeTime />
                </Stack>
                <Stack direction={{ md: 'column' }} sx={{ mt: 1, pb: 3 }} spacing={3}>
                    <Paper elevation={4} sx={{ bgcolor: '#DDDDDD', boxShadow: 0 }}>
                        <TabContext value={params.resource}>
                            <TabPanel value='researchers'>
                                <Researchers />
                            </TabPanel>
                            <TabPanel value='productsList'>
                                <ProductsList
                                    author={location?.state?.author || []}
                                    order={{
                                        type: 'relevance',
                                        variant: 'dsc',
                                        fields: ['relevance', 'date', 'citations']
                                    }}
                                />
                            </TabPanel>
                            <TabPanel value='productsSearch'>
                                <Products />
                            </TabPanel>
                            <TabPanel value='groupsSearch'>
                                <Groups />
                            </TabPanel>
                            <TabPanel value='projectsSearch'>
                                <Projects />
                            </TabPanel>
                        </TabContext>
                    </Paper>
                </Stack>
            </Container>
        </Box>
    );
}
const mapStateToProps = (state) => ({
    investigation: state.investigation,
    settings: state.settings
});
const mapDispatchToProps = (dispatch) => ({
    setInvestigation(state) {
        dispatch({
            type: 'SET_INVESTIGATION',
            state,
        });
    },
    setSettings(state) {
        dispatch({
            type: 'SET_SETTINGS',
            state,
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(Search);