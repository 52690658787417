import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import CardProductsList from '../../../../components/card/CardProductsList';
import { requestPost } from '../../../../../utils/request';
import { metricsInv, formatMessage } from '../../../../../utils/utils';
import { constants, URL_API_INV } from '../../../../../constants';

function ProductsList(props) {
    const { author, order, investigation, settings, setInvestigation, setSettings } = props;

    const { t } = useTranslation();
    const rowsPage = 100;

    const hierarchy = {
        ...investigation.hierarchy,
        resource: 'Lista de productos'
    };

    const handleFilter = (filter, record, response) => {
        if (record?.action?.type === 'Visualizar publicación en enlace externo (URL)') {
            requestPost(`${URL_API_INV}/usability/resource`, { record: record });
        } else {
            setSettings({ backdrop: formatMessage(true, '', t('dependence.investigation.consultingInfoListProducts')) });
            return Promise.all([
                requestPost(`${URL_API_INV}/investigation/products_list`, {
                    _ids: investigation.metrics.general[constants.METRIC_PUBLICATIONS_LIST_FIELD].processed,
                    filter: filter,
                    record: record
                })
            ])
                .then(res => {
                    if (response === 'state') {
                        setInvestigation(
                            metricsInv(
                                Object.keys(investigation.metrics.productsList),
                                res[0].data,
                                hierarchy,
                                investigation,
                                settings
                            )
                        );
                        setSettings({ backdrop: formatMessage(false, '', '') });
                    } else if (response === 'return') {
                        setSettings({ backdrop: formatMessage(false, '', '') });
                        return res[0].data[constants.METRIC_BOTH_FIELD][constants.METRIC_PUBLICATIONS_LIST_FIELD]
                    }
                })
                .catch(err => {
                    setSettings({
                        backdrop: formatMessage(false, '', ''),
                        snackbar: formatMessage(true, 'error', err),
                    });
                    throw err;
                });
        }
    };

    useEffect(() => {
        setSettings({ backdrop: formatMessage(true, '', t('dependence.investigation.consultingInfoListProducts')) });
        if (investigation.hierarchy.metrics.general || investigation.search.search) {
            Promise.all([
                requestPost(`${URL_API_INV}/investigation/products_list`, {
                    _ids: investigation.metrics.general[constants.METRIC_PUBLICATIONS_LIST_FIELD].processed,
                    filter: {
                        complete: false,
                        page: 0,
                        rowsPage: rowsPage,
                        contentSearch: '',
                        authorOption: author.map(item => item.value),
                        typeOption: [],
                        order: order
                    },
                    record: hierarchy
                })
            ])
                .then(res => {
                    setInvestigation(
                        metricsInv(
                            Object.keys(investigation.metrics.productsList),
                            res[0].data,
                            hierarchy,
                            investigation,
                            settings
                        )
                    );
                    setSettings({ backdrop: formatMessage(false, '', '') });
                })
                .catch(err => {
                    setSettings({
                        backdrop: formatMessage(false, '', ''),
                        snackbar: formatMessage(true, 'error', err),
                    });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [investigation.hierarchy.metrics.general, investigation.search.search]);

    return (
        <Box sx={{ bgcolor: 'white', p: 3 }}>
            <CardProductsList
                data={investigation.metrics.productsList[constants.METRIC_PUBLICATIONS_LIST_FIELD].processed}
                params={investigation.hierarchy.params}
                hierarchy={hierarchy}
                options={{
                    total: investigation.metrics.productsList[constants.METRIC_METRICS_FIELD].processed,
                    order: order,
                    rowsPage: rowsPage,
                    author: author,
                    curator: { deleted: false, edit: false },
                    metrics: {
                        author: investigation.metrics.general[constants.METRIC_AUTHORS_LIST_FIELD].processed,
                        type: investigation.metrics.general[constants.METRIC_TYPE_LIST_FIELD].processed
                    }
                }}
                handleFilter={handleFilter}
            />
        </Box>
    )
}
ProductsList.propTypes = {
    author: PropTypes.array,
    order: PropTypes.object
};
ProductsList.defaultProps = {
    author: [],
    order: {}
};
const mapStateToProps = (state) => ({
    investigation: state.investigation,
    settings: state.settings
});
const mapDispatchToProps = (dispatch) => ({
    setInvestigation(state) {
        dispatch({
            type: 'SET_INVESTIGATION',
            state,
        });
    },
    setSettings(state) {
        dispatch({
            type: 'SET_SETTINGS',
            state,
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(ProductsList);