import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import CardProductsList from '../../../../../components/card/CardProductsList';
import { formatMessage } from '../../../../../../utils/utils';
import { requestPost } from '../../../../../../utils/request';
import { constants, URL_API_INV } from '../../../../../../constants';

function Corrections(props) {
    const { author, order, type, investigation, login, setInvestigation, setLogin, setSettings } = props;

    const rowsPage = 100;

    const navigate = useNavigate();
    const hierarchy = {
        ...investigation.hierarchy,
        resource: type ? 'Lista de Correcciones' : 'Lista de Eliminados'
    };

    const handleFilter = (filter, record, response) => {
        setSettings({
            backdrop: formatMessage(false, 'warning', 'Funciones de filtro deshabilitadas.')
        });
    };
    
    useEffect(() => {
        if (investigation.hierarchy.stepKey) {
            setSettings({ backdrop: formatMessage(true, '', 'Consultando información de correcciones.') });
            Promise.all([
                requestPost(`${URL_API_INV}/curator/corrections`, {
                    type: type,
                    filter: {
                        complete: false,
                        page: 0,
                        rowsPage: rowsPage,
                        contentSearch: '',
                        authorOption: [],
                        typeOption: [],
                        order: order
                    },
                    record: hierarchy
                })
            ])
                .then(res => {
                    if (res[1].data.access && res[1].data.infoUser.dependence === 'Curaduria') {
                        setLogin({
                            admin: { infoUser: res[1].data.infoUser },
                            curator: {
                                publications: res[1].data.infoPublications[constants.METRIC_PUBLICATIONS_LIST_FIELD],
                                total: res[1].data.infoPublications[constants.METRIC_METRICS_FIELD]
                            }
                        });
                        setInvestigation({ hierarchy: hierarchy });
                    } else if (res[1].data.access) {
                        navigate('/login/Inicio de sesión', { state: { key: 'hierarchy' } });
                    } else {
                        sessionStorage.removeItem('log-ide');
                        navigate('/login/Inicio de sesión', { state: { key: 'hierarchy' } });
                    }
                    setSettings({ backdrop: formatMessage(false, '', '') });
                })
                .catch(err => {
                    setSettings({
                        backdrop: formatMessage(false, '', ''),
                        snackbar: formatMessage(true, 'error', err),
                    });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [investigation.hierarchy.stepKey]);

    return (
        <Box>
            <CardProductsList
                data={login.curator.publications}
                params={{ author: 'Curaduria' }}
                hierarchy={hierarchy}
                options={{
                    total: login.curator.total,
                    order: order,
                    rowsPage: rowsPage,
                    author: author,
                    curator: { deleted: false, edit: true },
                    metrics: {
                        author: login.curator.author,
                        type: login.curator.type
                    }
                }}
                handleFilter={handleFilter}
            />
        </Box>
    )
}
Corrections.propTypes = {
    author: PropTypes.array,
    order: PropTypes.object
};
Corrections.defaultProps = {
    author: [],
    order: {}
};
const mapStateToProps = (state) => ({
    investigation: state.investigation,
    login: state.login26
});
const mapDispatchToProps = (dispatch) => ({
    setInvestigation(state) {
        dispatch({
            type: 'SET_INVESTIGATION',
            state,
        });
    },
    setLogin(state) {
        dispatch({
            type: 'SET_LOGIN',
            state,
        });
    },
    setSettings(state) {
        dispatch({
            type: 'SET_SETTINGS',
            state,
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(Corrections);