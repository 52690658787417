import { useTranslation } from 'react-i18next';
import { Tooltip, Typography } from '@mui/material';
import { constants } from '../../../constants';
import { sortedArray } from '../../../utils/utils';

const CardAuthorList = ({ item, params, handleRoute, activeDependence }) => {
    const maxItems = 15;
    const { t } = useTranslation();
    
    const authors = sortedArray(item[constants.PUBLICATION_AUTHORS_FIELD], constants.AUTHOR_ISIN_FIELD, 'dsc', (bool) => Boolean(bool)).map((author, subKey) => {
        const separator = item[constants.PUBLICATION_AUTHORS_FIELD].length === subKey + 1 ? '.' : ', ';
        let name = constants.AUTHOR_NAME_FIELD in author ? author[constants.AUTHOR_NAME_FIELD] : author.name || {};
        name = typeof name === 'string' ? name : name[constants.PUBLICATION_NORMALIZED_SUBFIELD] ?? '';
        if (params.author === name) {
            return (
                <Typography key={subKey} variant='bold' component='label' sx={{ mr: 0.4 }}>
                    {name + separator}
                </Typography>
            );
        }
        if (author[constants.AUTHOR_ISIN_FIELD]) {
            return (
                <Tooltip key={subKey} title={t('components.card.cardProductsList.linkAuthor')}>
                    <Typography
                        component='label'
                        color='primary'
                        sx={{
                            '&:hover': {
                                textDecoration: 'underline',
                            },
                            cursor: 'pointer',
                            mr: 0.4,
                        }}
                        onClick={() => handleRoute(name)}
                    >
                        {name + separator}
                    </Typography>
                </Tooltip>
            );
        }
        return (
            <Typography key={subKey} component='label' sx={{ mr: 0.4 }}>
                {name + separator}
            </Typography>
        );
    });
    const displayAuthors = authors.slice(0, maxItems);

    return (
        <Typography variant='body1' color='text.secondary'>
            <Typography variant='bold' component='label' sx={{ mr: 0.4 }}>
                {activeDependence === 'Investigación'
                    ? t('components.card.cardProductsList.authorTrue') + ':'
                    : item.variant === 'ActivitiesCol'
                    ? t('components.card.cardProductsList.authorFalse') + ':'
                    : t('components.card.cardProductsList.authorFalseVariant') + ':'}
            </Typography>

            <Tooltip
                title={authors.length > maxItems ? authors : ''}
                disableHoverListener={authors.length <= maxItems}
            >
                <span>
                    {displayAuthors}
                    {authors.length > maxItems && '...'}
                </span>
            </Tooltip>
        </Typography>
    );
};
export default CardAuthorList;