import React from 'react';
import PropTypes from 'prop-types';
import { WordCloud as G2WordCloud } from '@antv/g2plot';
import ReactG2Plot from 'react-g2plot';
import { configWordCloud } from '../../../assets/theme/g2Theme';

function WordCloud(props) {
    const { infoChart } = props;
    return (
        <ReactG2Plot
            Ctor={G2WordCloud}
            options={{
                data: infoChart.data,
                ...configWordCloud
            }}
        />
    );
}
WordCloud.propTypes = {
    infoChart: PropTypes.shape({
        data: PropTypes.array
    })
};
export default WordCloud;