import React from 'react';
import PropTypes from 'prop-types';
import { LDAvis } from 'react-ldavis'
import 'react-ldavis/dist/index.css'

function customLDAvis(props) {
    const { infoChart } = props;
    return (
        <LDAvis
            data={infoChart.data || {}}
            modifyHistory={false}
            style={{ textAlign: 'center' }}
        />
    );
}
customLDAvis.propTypes = {
    infoChart: PropTypes.shape({
        data: PropTypes.object
    })
};
export default customLDAvis;