import { G2 } from '@antv/g2plot';

const { registerTheme } = G2;

export const configGraph3D = {
    backgroundColor: '#FFFFFF',
    linkColor: '#F73435'
};

export const configDefault = {
    height: 350,
    legend: {
        layout: 'horizontal',
        position: 'bottom',
    },
    theme: 'theme',
};

export const configBarGrouped = {
    ...configDefault,
    isGroup: true,
    xField: 'value',
    yField: 'group',
    seriesField: 'type',
};

export const configColumnStacked = {
    ...configDefault,
    isStack: true,
    xField: 'axis',
    yField: 'value',
    seriesField: 'type',
};

export const configPercent = {
    ...configDefault,
    xField: 'axis',
    yField: 'value',
    seriesField: 'type',
    isPercent: true,
    isStack: true,
};

export const configColumn = {
    ...configDefault,
    xField: 'axis',
    yField: 'value',
};

export const configMultiLine = {
    ...configDefault,
    xField: 'axis',
    yField: 'value',
    seriesField: 'type',
};

export const configLine = {
    ...configDefault,
    xField: 'type',
    yField: 'value',
};

export const configBarRadial = {
    ...configDefault,
    xField: 'type',
    yField: 'value',
    radius: 1,
    innerRadius: 0.3,
    startAngle: Math.PI * 0.5,
    endAngle: Math.PI * 2.5,
    colorField: 'type',
    barStyle: { lineCap: 'round' },
};

export const configColumnGroup = {
    ...configDefault,
    isGroup: true,
    xField: 'group',
    yField: 'value',
    seriesField: 'type',
};

export const configBoxesTree = {
    ...configDefault,
    colorField: 'name',
    interactions: [
        { type: 'view-zoom' },
        { type: 'drag-move' },
        { type: 'treemap-drill-down' }
    ],
    tooltip: {
        formatter: (v) => {
            return {
                title: v.path[1].data.name,
                name: v.path[0].data.name,
                value: v.path[0].data.value + (v.path[0].data?.children ? ' (Clic para abrir el siguiente nivel)' : '')
            };

        },
        showTitle: true
    },
    drilldown: {
        breadCrumb: {
            rootText: 'Inicio'
        }
    },
}
export const configCirclePacking = {
    ...configDefault,
    height: 450,
    padding: [0, 0, 40, 0],
    autoFit: true,
    hierarchyConfig: {
        padding: 0.01,
    },
    label: {
        formatter: ({ name }) => {
            return name !== 'root' ? name : '';
        },
        offsetY: 11,
        style: {
            fontSize: 14,
            textAlign: 'center',
            fontFamily: 'Ancizar sans',
            fill: '#000000'
        },
    }
}
export const configPie = {
    ...configDefault,
    appendPadding: 5,
    angleField: 'value',
    colorField: 'type',
    radius: 0.8,
    label: {
        type: 'inner',
        offset: '-30%',
        content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
        style: {
            fontSize: 16,
            textAlign: 'center',
            fontFamily: 'Ancizar sans',
        },
    },
    interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
};

export const configPieDonut = {
    ...configDefault,
    appendPadding: 10,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.65,
    label: false,
    interactions: [
        { type: 'element-selected' },
        { type: 'element-active' },
        { type: 'pie-statistic-active' },
    ],
};

export const configPieHalf = {
    ...configDefault,
    angleField: 'value',
    colorField: 'type',
    startAngle: Math.PI,
    endAngle: Math.PI * 2,
    radius: 0.8,
    label: {
        type: 'inner',
        offset: '-30%',
        content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
        style: {
            fontSize: 16,
            textAlign: 'center',
            fontFamily: 'Ancizar sans',
        },
    },
    interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
};

export const configPieHalfStacked = {
    ...configDefault,
    height: 175,
    angleField: 'value',
    colorField: 'type',
    startAngle: Math.PI,
    endAngle: Math.PI * 2,
    radius: 0.8,
    label: {
        type: 'inner',
        offset: '-30%',
        content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
        style: {
            fontSize: 16,
            textAlign: 'center',
            fontFamily: 'Ancizar sans',
        },
    },
    interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
};

export const configWordCloud = {
    ...configDefault,
    wordField: 'name',
    weightField: 'value',
    colorField: 'name',
    wordStyle: {
        fontFamily: 'Ancizar sans',
        fontSize: [15, 50],
    },
    random: () => 0.5,
};

export const configDiagramArc = {
    ...configDefault,
    height: configDefault.height + 50,
    linkCenter: false,
    modes: {
        default: ['drag-canvas', 'zoom-canvas', 'drag-node'],
    },
    defaultNode: {
        type: 'circle',
        size: [30],
        style: {
            lineWidth: 0,
        },
        labelCfg: {
            position: 'center',
            style: {
                fontSize: 13.5,
                fontFamily: 'Ancizar sans',
                rotateCenter: 'lefttop',
                textAlign: 'start',
                rotate: 0,
            },
        },
    },
    defaultEdge: {
        style: {
            opacity: 0.8,
            lineAppendWidth: 3,
        },
    },
};

export const configDiagramChord = {
    ...configDefault,
    height: configDefault.height + 50,
    sourceField: 'sourceName',
    targetField: 'targetName',
    weightField: 'size',
    padding: [35, 35, 35, 35],
    label: {
        offset: 3,
        style: {
            fill: '#000',
            fontSize: 13.5,
            fontFamily: 'Ancizar sans',
        },
    },
};

export const configDiagramForce = {
    ...configDefault,
    height: configDefault.height + 50,
    layout: {
        type: 'force2',
        animate: false,
        maxSpeed: 100,
        linkDistance: 50,
        clustering: true,
        nodeClusterBy: 'cluster',
        clusterNodeStrength: 50,
    },
    modes: {
        default: ['drag-canvas', 'zoom-canvas', 'drag-node'],
    },
    defaultNode: {
        type: 'circle',
        size: [30],
        style: {
            lineWidth: 0,
        },
        labelCfg: {
            position: 'center',
            style: {
                fontSize: 13.5,
                fontFamily: 'Ancizar sans',
                rotateCenter: 'lefttop',
                textAlign: 'start',
                rotate: 0,
            },
        },
    },
    defaultEdge: {
        style: {
            opacity: 0.8,
            lineAppendWidth: 3,
        },
    },
};

export const configColumnGroupStacked = {
    ...configDefault,
    xField: 'axis',
    yField: 'value',
    seriesField: 'type',
    groupField: 'group',
    isGroup: true,
    isStack: true
};

export const configBar = {
    height: 350,
    xField: 'value',
    yField: 'type',
    barWidthRatio: 0.8,
    label: {
        content: (item) => {
            return item.type;
        },
        position: 'left',
        style: {
            fontSize: 14,
            fontFamily: 'Ancizar sans',
            fill: '#000000',
        },
    },
    yAxis: false,
    color: '#1890FF',
    theme: 'theme',
};

export const configColor = [
    '#1890FF',
    '#04297A',
    '#7f2eff',
    '#ff2e44',
    '#2effe8',
    // '#fffe00',
    '#74BA43',
    '#00ff80',
    // '#669900',
    // '#ccee66',
    '#006699',
    '#3399cc',
    '#990066',
    '#cc3399',
    '#ff6600',
    '#ff9900',
    '#ff007f',
    '#0000ff',
    '#99cc33',
    // '#adff2e',
];
export const genderColorMap = {
    Femenino: '#D86666',
    Directoras: '#D86666',
    Masculino: '#207093',
    Directores: '#207093',
    'Total femenino': '#D3D1CE',
    'Total masculino': '#D3D1CE',
    'No directoras': '#D3D1CE',
    'No directores': '#D3D1CE',
    Female: '#D86666',
    'Female directors': '#D86666',
    Male: '#207093',
    'Male directors': '#207093',
    'Total female': '#D3D1CE',
    'Total male': '#D3D1CE',
    'No female directors': '#D3D1CE',
    'No male directors': '#D3D1CE',
    '': '#D3D1CE',
    'F': '#D86666',
    'M': '#207093'
}
export const configColorGender = {
    colorField: 'type',
    color: ({ type }) => genderColorMap[type]
}
export const configColorGenderF = ['#207093', '#D3D1CE'];
export const configColorGenderM = ['#D86666', '#D3D1CE'];
const g2Theme = {
    styleSheet: {
        fontFamily: 'Ancizar sans',
    },
    components: {
        axis: {
            common: {
                label: {
                    style: {
                        fill: '#000000',
                        fontSize: 14,
                        fontFamily: 'Ancizar sans',
                    },
                },
            },
        },
        tooltip: {
            domStyles: {
                'g2-tooltip': {
                    color: '#000000',
                    fontSize: 14,
                    fontFamily: 'Ancizar sans',
                },
            },
        },
        legend: {
            common: {
                itemName: {
                    style: {
                        fill: '#000000',
                        fontFamily: 'Ancizar sans',
                        fontSize: 14,
                        textAlign: 'start',
                    },
                },
            },
            top: {
                layout: 'horizontal',
                padding: [10, 0, 20, 0],
            },
            bottom: {
                layout: 'horizontal',
                padding: [20, 0, 10, 0],
            },
        },
    },
    colors10: configColor.slice(0, 10),
    colors20: configColor,
    genderColor: genderColorMap
};

registerTheme('theme', g2Theme);
export default g2Theme;