import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Divider, Grid, Box, Typography, Paper, Stack } from '@mui/material';
import { ContentPasteSearchTwoTone, GroupTwoTone } from '@mui/icons-material';
import InvAppbar from '../../components/appbar/InvAppbar';
import CardNews from '../../components/card/CardNews';
import Carousel from '../../components/Carousel';
import Bogota from '../../../assets/images/horus/campus/Bogotá.jpg';
import Caribe from '../../../assets/images/horus/campus/Caribe.jpg';
import La_Paz from '../../../assets/images/horus/campus/La_Paz.jpg';
import Manizales from '../../../assets/images/horus/campus/Manizales.jpg';
import Medellin from '../../../assets/images/horus/campus/Medellin.jpg';
import Palmira from '../../../assets/images/horus/campus/Palmira.jpg';

const backgroundImages = [Bogota, Caribe, La_Paz, Manizales, Medellin, Palmira].map(image => {
    const img = new Image();
    img.src = image;
    return img;
});

function Home(props) {
    const { login } = props;
    const [imageIndex, setImageIndex] = useState(0);

    const { t } = useTranslation();
    const params = useParams();
    const navigate = useNavigate();

    const handleRoute = (route) => {
        navigate(route, { state: { key: 'init' } });
    };
    
    useEffect(() => {
        const intervalId = setInterval(() => {
            setImageIndex((prevIndex) => (prevIndex + 1) % backgroundImages.length);
        }, 6000);
        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [backgroundImages]);

    return (
        <Stack
            direction='column'
            justifyContent='start'
            alignItems='center'
            sx={{
                width: '100%',
                height: '100%',
                pt: { xs: 3, sm: 0 },
                minHeight: '100vh',
                backgroundImage: `url(${backgroundImages[imageIndex].src})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundAttachment: 'fixed',
                transition: 'background-image 5s ease-in-out'
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '0px',
                    width: '100%',
                    height: '300px',
                    background: 'linear-gradient(180deg, rgba(0,0,0,0.5) 20%, rgba(0,0,0,0) 90%)',
                    zIndex: 0
                }}
            />
            <Stack
                direction='row'
                spacing={2}
                alignItems='center'
                justifyContent='center'
                divider={
                    <Divider
                        orientation='vertical'
                        flexItem
                        sx={{ bgcolor: 'white', width: '2px', }}
                    />
                }
                sx={{ width: '100%', zIndex: '1', mt: { xs: 0, sm: 4 }, mb: { xs: 0, sm: 2 } }}
            >
                <Box
                    component='img'
                    alt='Logo Horus'
                    sx={{ height: 50, mb: 3 }}
                    src={require('../../../assets/images/horus/logoHorus.png')}
                />
                <Typography
                    variant='body'
                    sx={{
                        color: 'white',
                        lineHeight: 1.2,
                        fontSize: '1.2em',
                        maxWidth: '300px',
                        textShadow: '0 0 4px rgba(0,0,0,0.5)'
                    }}
                    dangerouslySetInnerHTML={{ __html: t('home.description') }}
                />
            </Stack>
            <InvAppbar hierarchy='research' params={params} value='' />
            <Grid
                container
                spacing={3}
                justifyContent='space-around'
                alignItems='space-around'
                sx={{ width: { xs: '90%', md: '80%', lg: '70%' }, mb: 2, mt: 11 }}
            >
                <Grid item xs={12} sm={7}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} >
                        <Button
                            onClick={() => handleRoute('/institution/Universidad Nacional de Colombia/resource/products')}
                            sx={{
                                bgcolor: theme => theme.palette['darkerBlue'].main,
                                boxShadow: '2px 2px 3px rgba(0,0,0,0.3)',
                                backdropFilter: 'blur(8px)',
                                width: '100%',
                                height: '150px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: 1,
                                transition: 'all 0.2s',
                                '&:hover': {
                                    bgcolor: theme => theme.palette['green'].main,
                                },
                                '&:focus': {
                                    outline: 'transparent',
                                },
                            }}
                        >
                            <Stack
                                direction='column'
                                spacing={2}
                                justifyContent='center'
                                alignItems='center'
                                sx={{ mb: '0px', width: '90%' }}
                            >
                                <ContentPasteSearchTwoTone sx={{ color: 'white', width: 36, height: 36, }} />
                                <Typography
                                    variant='body'
                                    sx={{
                                        color: 'white',
                                        lineHeight: 1,
                                        fontSize: '1.2em',
                                        textShadow: '0 0 4px rgba(0,0,0,0.5)',
                                        fontWeight: 'normal',
                                        textAlign: 'center',
                                    }}
                                    dangerouslySetInnerHTML={{ __html: t('dependence.investigation.products') }}
                                />
                            </Stack>
                        </Button>
                        <Button
                            onClick={() => handleRoute('/institution/Universidad Nacional de Colombia/resource/researchers')}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                bgcolor: theme => theme.palette['darkerBlue'].main,
                                boxShadow: '2px 2px 3px rgba(0,0,0,0.3)',
                                backdropFilter: 'blur(8px)',
                                width: '100%',
                                height: '150px',
                                borderRadius: 1,
                                '&:hover': {
                                    bgcolor: theme => theme.palette['green'].main,
                                },
                                '&:focus': {
                                    outline: 'transparent',
                                }
                            }}
                        >
                            <Stack
                                direction='column'
                                spacing={1}
                                justifyContent='center'
                                alignItems='center'
                                sx={{ mb: '0px', width: '90%' }}
                            >
                                <GroupTwoTone sx={{ color: 'white', width: 40, height: 40, }} />
                                <Typography
                                    variant='body'
                                    sx={{
                                        color: 'white',
                                        lineHeight: 1,
                                        fontSize: '1.2em',
                                        textShadow: '0 0 4px rgba(0,0,0,0.5)',
                                        fontWeight: 'normal',
                                        textAlign: 'center',
                                    }}
                                    dangerouslySetInnerHTML={{ __html: t('dependence.investigation.researchers') }}
                                />
                            </Stack>
                        </Button>
                        <Button
                            onClick={() => handleRoute('/institution/Universidad Nacional de Colombia/resource/groups')}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                bgcolor: theme => theme.palette['darkerBlue'].main,
                                boxShadow: '2px 2px 3px rgba(0,0,0,0.3)',
                                backdropFilter: 'blur(8px)',
                                width: '100%',
                                height: '150px',
                                borderRadius: 1,
                                '&:hover': {
                                    bgcolor: theme => theme.palette['green'].main,
                                },
                                '&:focus': {
                                    outline: 'transparent',
                                }
                            }}
                        >
                            <Stack
                                direction='column'
                                spacing={1}
                                justifyContent='center'
                                alignItems='center'
                                sx={{ mb: '0px', width: '90%' }}
                            >
                                <GroupTwoTone sx={{ color: 'white', width: 40, height: 40, }} />
                                <Typography
                                    variant='body'
                                    sx={{
                                        color: 'white',
                                        lineHeight: 1,
                                        fontSize: '1.2em',
                                        textShadow: '0 0 4px rgba(0,0,0,0.5)',
                                        fontWeight: 'normal',
                                        textAlign: 'center',
                                    }}
                                    dangerouslySetInnerHTML={{ __html: t('dependence.investigation.groups') }}
                                />
                            </Stack>
                        </Button>
                    </Stack>
                    <Stack direction='column' spacing={0} sx={{ mt: { xs: 2, sm: 4 } }}>
                        <Paper elevation={2} sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'white',
                            p: 3,
                            borderBottomLeftRadius: 0, borderBottomRightRadius: 0,
                            borderTopLeftRadius: 1, borderTopRightRadius: 1,
                            maxWidth: '100%',
                            overflow: 'hidden'
                        }}>
                            <Carousel
                                functionalities={login.news.infoNews.filter((item) => item.type === 'image')}
                            />
                        </Paper>
                        <Box
                            sx={{
                                bgcolor: theme => theme.palette['green'].main,
                                height: 40,
                                width: '100%',
                                borderBottomLeftRadius: 1, borderBottomRightRadius: 1,
                                borderTopLeftRadius: 0, borderTopRightRadius: 0,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Stack direction='row' spacing={1} sx={{
                                display: 'flex',
                                alignItems: 'center', justifyContent: 'flex-end',
                                width: '100%',
                                mr: 2,
                            }}>
                                <Box
                                    component='img'
                                    alt='Logo Horus'
                                    sx={{ height: 22.5 }}
                                    src={require('../../../assets/images/horus/logoHorus.png')}
                                />
                                <Divider
                                    orientation='vertical'
                                    flexItem
                                    sx={{ bgcolor: 'white', width: 0.001, height: 30, alignSelf: 'center' }}
                                />
                                <Box
                                    component='img'
                                    alt='Logo Horus'
                                    sx={{ height: 40 }}
                                    src={require('../../../assets/images/unal/escudoUnal.png')}
                                />
                            </Stack>
                        </Box>
                    </Stack >
                </Grid>
                <Grid item xs={12} sm={5}>
                    <CardNews
                        news={login.news.infoNews.filter((item) => item.type === 'text')}
                    />
                </Grid>
            </Grid>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', mt: 'auto', p: 2, bgcolor: 'transparent' }}>
                <Typography variant='body2' color='white'>
                    &copy; Unimedios UNAL
                </Typography>
            </Box>
        </Stack>
    );
};
const mapStateToProps = (state) => ({
    login: state.login
});
export default connect(mapStateToProps)(Home);