import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Grid } from '@mui/material';
import Scrollbar from '../Scrollbar';
import { formatCurrency, formatNumber, sortedArray } from '../../../utils/utils';

const getColorBackground = (value, min, max) => {
    const inverseSrgbCompanding = (v) => {
        return v <= 0.04045 ? v / 12.92 : ((v + 0.055) / 1.055) ** 2.4;
    };
    const rgbCompanding = (v) => {
        return v <= 0.0031308 ? v * 12.92 : 1.055 * v ** (1 / 2.4) - 0.055;
    };
    const rgbCompadingColor = (color) => {
        return {
            r: rgbCompanding(color.r / 255) * 255,
            g: rgbCompanding(color.g / 255) * 255,
            b: rgbCompanding(color.b / 255) * 255,
        };
    };
    const inverseSrgbCompandingColor = (color) => {
        return {
            r: inverseSrgbCompanding(color.r / 255) * 255,
            g: inverseSrgbCompanding(color.g / 255) * 255,
            b: inverseSrgbCompanding(color.b / 255) * 255,
        };
    };
    const valueInRange = (value - min) / (max - min);
    const color1 = {
        r: 255,
        g: 255,
        b: 255,
    };
    const color2 = {
        r: 24,
        g: 144,
        b: 255,
    };
    const invColor1 = inverseSrgbCompandingColor(color1);
    const invColor2 = inverseSrgbCompandingColor(color2);
    let result = {
        r: invColor1.r * (1 - valueInRange) + invColor2.r * valueInRange,
        g: invColor1.g * (1 - valueInRange) + invColor2.g * valueInRange,
        b: invColor1.b * (1 - valueInRange) + invColor2.b * valueInRange,
    };
    result = rgbCompadingColor(result);
    return result;
};
const RowScale = ({ l_val, r_val, r, g, b }) => (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Grid
            container
            spacing={0}
            sx={{ background: `linear-gradient(to right, rgb(255,255,255), rgb(${r},${g},${b}))` }}
        >
            <Grid item sm={9} xs={7}>
                <Typography>
                    {l_val}
                </Typography>
            </Grid>
            <Grid item sm={3} xs={5}>
                <Typography variant='bold' sx={{ float: 'right', mr: 1 }}>
                    {r_val}
                </Typography>
            </Grid>
        </Grid>
    </Box>
);
const BarGradient = (props) => {
    const { infoChart } = props;
    const newData = sortedArray(infoChart.data, infoChart.sortField, 'dsc', (int) => parseInt(int));
    const max = Math.max.apply(Math, newData?.map((item) => Math.log2(item.value)));
    const min = -1;
    return (
        <Scrollbar sx={{ maxHeight: 350 }}>
            {newData?.map((item, key) => {
                const { r, g, b } = getColorBackground(Math.log2(item.value), min, max);
                return (
                    <RowScale
                        key={key}
                        l_val={item.type}
                        r={r}
                        g={g}
                        b={b}
                        r_val={infoChart.formatValue === 'number' ? formatNumber(item.value) : formatCurrency(item.value)}
                    />
                );
            })}
        </Scrollbar>
    );
};
BarGradient.propTypes = {
    infoChart: PropTypes.shape({
        data: PropTypes.array,
    })
};
export default BarGradient;