import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Paper, Box, InputBase, Menu, ListSubheader, Typography, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import Tooltip from '../Tooltip';
import TreeSearch from '../tree/TreeSearch';
import { requestPost } from '../../../utils/request';
import { formatDependencesName, getParamsRoute, metricsExt } from '../../../utils/utils';
import { constants, URL_API_INV } from '../../../constants';

function SearchDependences(props) {
    const { dataTree, hierarchy, params, extension, setMetricsDefault, setExtension } = props;
    const [search, setSearch] = useState({
        menu: null,
        value: ''
    });

    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleClose = () => {
        setSearch({ ...search, menu: null });
    };

    const handleChange = (target, field, value) => {
        if (field === 'value') {
            setSearch({
                menu: target,
                value: value
            });
        } else if (field === 'menu') {
            setSearch({
                menu: target,
                value: value
            });
        }
    };

    const handleClick = (node) => {
        handleClose();
        if (hierarchy === 'research') {
            setMetricsDefault();
            node.info.route.resource = params.resource || 'products';
            const route = getParamsRoute(node.info.route);
            navigate(route, { state: { key: 'hierarchy' } });
        } else if (hierarchy === 'extension') {
            const nameLevel = {
                DEPENDENCE_FACULTY_FIELD: 'Facultad',
                DEPENDENCE_UAB_FIELD: 'Uab',
                DEPENDENCE_AUTHOR_FIELD: 'Profesor'
            };
            const resource = node.info.level === 'DEPENDENCE_AUTHOR_FIELD'
                ? 'Perfil de profesor'
                : extension.hierarchy.resource;
            const hierarchy = {
                ...extension.hierarchy,
                hierarchy: {
                    ...extension.hierarchy.hierarchy,
                    dependence: [false, 'Extensión'],
                    hierarchy: [nameLevel[node.info.level], node.name],
                },
                resource: resource
            };
            extension.filters.dependence.applied = node.info;
            extension.hierarchy = hierarchy;
            requestPost(`${URL_API_INV}/usability/hierarchy`, { record: hierarchy });
            requestPost(`${URL_API_INV}/usability/resource`, { record: hierarchy });
            if (node.info.level === 'DEPENDENCE_AUTHOR_FIELD') {
                // setSettings({ backdrop: formatMessage(true, '', 'Consultando información del profesor.') });
                // Promise.all([
                //     requestPost(`${URL_API_EXT}/extension/projectsTeacher`, { value: route.name ? route.name : route._id }),
                // ])
                //     .then(res => {
                //         if (res[0].data.profile !== null && res[0].data.projects.length !== 0) {
                //             handleClose();
                //             setExtension({
                //                 hierarchy: hierarchy,
                //                 metrics: {
                //                     author: {
                //                         dialog: true,
                //                         data: res[0].data,
                //                         // resource: resource
                //                     }
                //                 }
                //             });
                //             setSettings({ backdrop: formatMessage(false, '', '') });
                //             handleClose();
                //         } else {
                //             setSettings({ backdrop: formatMessage(false, '', '') });
                //             setSettings({ snackbar: formatMessage(true, 'warning', 'No se encontró información del profesor, por favor vuelva a intentar.') });
                //         }
                //     })
                //     .catch(err => {
                //         setSettings({
                //             backdrop: formatMessage(false, '', ''),
                //             snackbar: formatMessage(true, 'error', err),
                //         });
                //     });
            } else {
                setExtension(
                    metricsExt(
                        Object.keys(extension.metrics[constants.RESOURCES[resource]]),
                        extension.metrics[constants.RESOURCES[resource]].source,
                        extension.hierarchy,
                        extension
                    )
                );
            }
        }
    };

    return (
        <Box>
            <Paper
                elevation={10}
                sx={{ display: 'flex', width: '100%', height: 35, borderRadius: 1, borderTopLeftRadius: 0 }}
            >
                <InputBase
                    value={formatDependencesName(search.value)}
                    onChange={(event) => handleChange(event.target, 'value', event.target.value)}
                    onClick={(event) => handleChange(event.target, 'menu', search.value)}
                    placeholder={t('components.search.SearchDependences.placeholder')}
                    sx={{ flex: 1, ml: 3, fontSize: 16, fontWeight: 500 }}
                />
                <Tooltip title={t('components.search.SearchDependences.tooltip')}>
                    <IconButton
                        sx={{
                            p: '10px',
                            '&:focus': {
                                outline: 'transparent',
                            },
                        }}
                        onClick={() => handleChange(search.menu, 'value', '')}
                    >
                        <Close />
                    </IconButton>
                </Tooltip>
            </Paper>
            <Menu
                anchorEl={search.menu}
                open={Boolean(search.menu)}
                onClose={handleClose}
                disableAutoFocus={true}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                sx={{
                    mt: 1,
                    '& .MuiMenu-paper': { borderRadius: 1, width: { xs: '95%', md: '55%' } }
                }}
            >
                <ListSubheader sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Typography variant='body1' sx={{ mt: 0.5, mb: 1.25 }}>
                        {t('components.search.SearchDependences.results')}
                    </Typography>
                </ListSubheader>
                <TreeSearch
                    height={300}
                    dataTree={dataTree}
                    params={params}
                    search={search.value}
                    handleClick={(value) => handleClick(value)}
                />
            </Menu>
        </Box>
    );
}
SearchDependences.propTypes = {
    hierarchy: PropTypes.string,
    params: PropTypes.object,
    value: PropTypes.string
};
const mapStateToProps = (state) => ({
    dependence: state.dependence,
    extension: state.extension
});
const mapDispatchToProps = (dispatch) => ({
    setMetricsDefault() {
        dispatch({
            type: 'SET_METRICS_DEFAULT'
        });
    },
    setExtension(state) {
        dispatch({
            type: 'SET_EXTENSION',
            state,
        });
    },
    setSettings(state) {
        dispatch({
            type: 'SET_SETTINGS',
            state,
        });
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(SearchDependences);