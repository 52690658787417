import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Grid, Box, Card, CardHeader, CardContent, CardActions, Collapse, Autocomplete, TextField, FormControlLabel, Switch, DialogContentText, CardMedia, Stack, IconButton, Fab, Typography, Menu, MenuItem, List, ListItem, ListItemText, ListItemIcon, Divider } from '@mui/material';
import { ManageHistoryTwoTone, InfoTwoTone, GetAppTwoTone, TableChartOutlined, FileOpenTwoTone, PermMediaTwoTone, LocalPrintshopTwoTone } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Tooltip from '../Tooltip';
import Dialog from '../Dialog';
import DataTable from '../DataTable';
import CardEmpty from './CardEmpty';
import { styled } from '@mui/material/styles';
import { requestPost } from '../../../utils/request';
import { formatDownloadScreen, formatDownloadPrint, formatDownloadCsv, formatCurrency, formatNumber, validateEmptyData } from '../../../utils/utils';
import { URL_API_INV } from '../../../constants';

function CardGraphics(props) {
    const { title, tools, infoDialog, infoChart, children, investigation, extension, settings } = props;
    const [menu, setMenu] = useState({
        tools: null,
        export: null
    });

    const { t } = useTranslation();
    const processed = infoChart.data.processed;
    const toolsState = infoChart.data.tools;

    const handleTool = (tool, value, usability, subResource, subResourceValue) => {
        const newState = {
            tools: {
                ...toolsState,
                [tool]: {
                    ...toolsState[tool],
                    value: value,
                },
            },
            processed: processed,
        };
        infoChart.onChange(newState);
        if (usability) handleSubResource(subResource, subResourceValue);
    };
    const handleDownload = (format, data) => {
        if (format === 'image') {
            formatDownloadScreen(title);
            handleSubResource('Descarga de imagen de la gráfica', 'Clic en botón de descarga de imagen');
        } else if (format === 'csv') {
            formatDownloadCsv(title, data);
            handleSubResource('Descarga de CSV de la gráfica', 'Clic en botón de descarga de CSV');
        } else if (format === 'print') {
            formatDownloadPrint(title);
            handleSubResource('Imprimir la gráfica', 'Clic en botón de imprimir');
        }
    };
    const handleSubResource = (type, value) => {
        if (tools.usability) {
            let defaultHierarchy = {
                init: '',
                dependence: [false, ''],
                hierarchy: ['', ''],
            };
            if (investigation.hierarchy.hierarchy?.dependence?.[1] === 'Investigación') {
                defaultHierarchy = investigation.hierarchy;
            } else if (extension.hierarchy.hierarchy?.dependence?.[1] === 'Extensión') {
                defaultHierarchy = extension.hierarchy;
            }
            const hierarchy = {
                ...defaultHierarchy,
                subResource: title,
                action: { type: type, value: value },
            };
            requestPost(`${URL_API_INV}/usability/subresource`, { record: hierarchy });
        }
    };
    const columnsTable = Object.keys(infoChart.columns).map((item) => {
        return {
            field: item,
            headerName: infoChart.columns[item],
            flex: 1,
            type: ['value', 'values', 'percent'].includes(item) ? 'number' : 'string',
            renderCell: (params) => item === 'value'
                ? infoChart.formatValue === 'money'
                    ? formatCurrency(params.row.value)
                    : infoChart.formatValue === 'number'
                        ? formatNumber(params.row.value)
                        : infoChart.formatValue === 'percent'
                            ? params.row.value + '%'
                            : params.row.value
                : params.row[item],
        };
    });
    const handleMenu = (event, type) => {
        setMenu({ ...menu, [type]: event.currentTarget });
    };
    const handleClose = (type) => {
        setMenu({ ...menu, [type]: null });
    };
    const IconWrapperStyle = styled('div')(() => ({
        m: 5,
        top: 12,
        right: 20,
        width: 30,
        height: 30,
        color: 'white'
    }));
    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'space-between'
            }}
        >
            <Card
                id={title + 'screen'}
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                }}
            >
                <CardHeader
                    sx={{
                        borderBottom: 1, borderColor: 'divider',
                        color: 'black'
                    }}
                    title={
                        <Grid alignItems='center' sx={{ display: 'inline-flex' }}>
                            <Box sx={{ display: tools.rangeTime ? 'inherit' : 'none' }}>
                                <Tooltip title={t('components.card.cardGraphics.filterTime')}>
                                    <IconWrapperStyle sx={{
                                        backgroundColor: theme => theme.palette['green'].main,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: 1,
                                        display: 'flex',
                                        width: 32,
                                        height: 32,
                                    }}>
                                        <ManageHistoryTwoTone />
                                    </IconWrapperStyle>
                                </Tooltip>
                            </Box>
                            <Typography sx={{ paddingLeft: 1 }} variant='h6'>{title}</Typography>
                        </Grid>
                    }
                    action={
                        <Stack
                            spacing={1}
                            alignItems='center'
                            direction={{ xs: 'column', sm: 'row' }}
                            sx={{ ml: 1, display: (tools.gender || tools.type || tools.accumulate || tools.wordCloud || tools.typeChart || tools.dataTable || tools.dialog) ? 'inline-flex' : 'none' }}
                            marginRight={1}
                            marginLeft={1}
                        >
                            <IconButton
                                onClick={(event) => handleMenu(event, 'tools')}
                                sx={{
                                    width: 40,
                                    height: 40,
                                    border: '2px solid white',
                                    color: '#212B36',
                                    borderRadius: 2,
                                    '&:hover': {
                                        opacity: 0.5,
                                    },
                                    '&:focus': {
                                        outline: 'transparent',
                                    },
                                    '&:active': {
                                        border: '3px solid white',
                                    }
                                }}
                            >
                                <MoreVertIcon fontSize='large' color='white' />
                            </IconButton>
                            <Menu
                                disableScrollLock
                                anchorEl={menu.tools}
                                open={Boolean(menu.tools)}
                                onClose={() => handleClose('tools')}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                PaperProps={{ style: { width: 200 } }}
                                sx={{ zIndex: 50 }}
                            >
                                <List>
                                    <ListItem sx={{ display: tools.gender ? 'inherit' : 'none' }}>
                                        <Tooltip title={t('components.card.cardGraphics.genderTooltip')} placement='left'>
                                            <Autocomplete
                                                fullWidth
                                                size='small'
                                                disableClearable
                                                onChange={(event, value) => handleTool('gender', value, true, 'Cambio de género', value.label_es)}
                                                value={toolsState.gender.value}
                                                options={toolsState.gender.options}
                                                getOptionLabel={(option) => option['label_' + settings.language]}
                                                renderInput={(params) => (
                                                    <TextField {...params} label={t('components.card.cardGraphics.gender')} />
                                                )}
                                            />
                                        </Tooltip>
                                    </ListItem>
                                    <Divider sx={{ mb: 1, display: tools.gender ? 'inherit' : 'none' }} />
                                    <ListItem sx={{ display: tools.type ? 'inherit' : 'none' }}>
                                        <Tooltip title={t('components.card.cardGraphics.typeTooltip')} placement='left'>
                                            <Autocomplete
                                                fullWidth
                                                size='small'
                                                disableClearable
                                                onChange={(event, value) => handleTool('type', value, true, 'Cambio de tipo de publicación', value.label_es)}
                                                value={toolsState.type.value}
                                                options={toolsState.type.options}
                                                getOptionLabel={(option) => option['label_' + settings.language]}
                                                renderInput={(params) => (
                                                    <TextField {...params} label={t('components.card.cardGraphics.type')} />
                                                )}
                                            />
                                        </Tooltip>
                                    </ListItem>
                                    <Divider sx={{ mb: 1, display: tools.type ? 'inherit' : 'none' }} />
                                    <ListItem sx={{ display: tools.accumulate ? 'inherit' : 'none' }}>
                                        <Tooltip title={toolsState.accumulate.value ? t('components.card.cardGraphics.accumulateTrueTooltip') : t('components.card.cardGraphics.accumulateFalseTooltip')} placement='left'>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={toolsState.accumulate.value}
                                                        onChange={() => handleTool('accumulate', !toolsState.accumulate.value, !toolsState.accumulate.value, 'Visualización de gráfica con datos acumulados', 'Clic en botón de acumulado')}
                                                    />
                                                }
                                                label={toolsState.accumulate.value ? t('components.card.cardGraphics.accumulateTrue') : t('components.card.cardGraphics.accumulateFalse')}
                                                sx={{
                                                    ml: 0.08,
                                                    borderRadius: 1,
                                                    border: '1px solid #C0C0C0',
                                                    width: '100%'
                                                }}
                                            />
                                        </Tooltip>
                                    </ListItem>
                                    <Divider sx={{ mb: 1, display: tools.accumulate ? 'inherit' : 'none' }} />
                                    <ListItem sx={{ display: tools.wordCloud ? 'inherit' : 'none' }}>
                                        <Tooltip title={t('components.card.cardGraphics.wordCloudTypeTooltip')} placement='left'>
                                            <Autocomplete
                                                fullWidth
                                                size='small'
                                                disableClearable
                                                onChange={(event, value) => handleTool('wordCloudType', value, true, 'Cambio de tipo de nube de palabras', value.label_es)}
                                                value={toolsState.wordCloudType.value}
                                                options={toolsState.wordCloudType.options}
                                                getOptionLabel={(option) => option['label_' + settings.language]}
                                                renderInput={(params) => (
                                                    <TextField {...params} label={t('components.card.cardGraphics.wordCloudType')} />
                                                )}
                                            />
                                        </Tooltip>
                                    </ListItem>
                                    <Divider sx={{ mb: 1, display: tools.wordCloud ? 'inherit' : 'none' }} />
                                    <ListItem sx={{ display: tools.wordCloud ? 'inherit' : 'none' }}>
                                        <Tooltip title={t('components.card.cardGraphics.wordCloudLanguageTooltip')} placement='left'>
                                            <Autocomplete
                                                fullWidth
                                                size='small'
                                                disableClearable
                                                onChange={(event, value) => handleTool('wordCloudLanguage', value, true, 'Cambio de idioma', value.label_es)}
                                                value={toolsState.wordCloudLanguage.value}
                                                options={toolsState.wordCloudLanguage.options}
                                                getOptionLabel={(option) => option['label_' + settings.language]}
                                                renderInput={(params) => (
                                                    <TextField {...params} label={t('components.card.cardGraphics.wordCloudLanguage')} />
                                                )}
                                            />
                                        </Tooltip>
                                    </ListItem>
                                    <Divider sx={{ mb: 1, display: tools.wordCloud ? 'inherit' : 'none' }} />
                                    <ListItem sx={{ display: tools.typeChart ? 'inherit' : 'none' }}>
                                        <Tooltip title={t('components.card.cardGraphics.typeChartTooltip')} placement='left'>
                                            <Autocomplete
                                                fullWidth
                                                size='small'
                                                disableClearable
                                                onChange={(event, value) => handleTool('typeChart', value, true, 'Cambio de tipo de gráfica', value.label_es)}
                                                value={toolsState.typeChart.value}
                                                options={toolsState.typeChart.options}
                                                getOptionLabel={(option) => option['label_' + settings.language]}
                                                renderInput={(params) => (
                                                    <TextField {...params} label={t('components.card.cardGraphics.typeChart')} />
                                                )}
                                            />
                                        </Tooltip>
                                    </ListItem>
                                    <Divider sx={{ mb: 1, display: tools.typeChart ? 'inherit' : 'none' }} />
                                    <MenuItem
                                        sx={{ display: tools.dataTable ? 'flex' : 'none' }}
                                        onClick={() => handleTool('dataTable', !toolsState.dataTable.value, !toolsState.dataTable.value, 'Visualización de la tabla de datos', 'Clic en botón de tabla de datos')}
                                    >
                                        <ListItemIcon>
                                            <TableChartOutlined color='primary' />
                                        </ListItemIcon>
                                        <ListItemText>{toolsState.dataTable.value ? t('components.card.cardGraphics.dataTableFalseTooltip') : t('components.card.cardGraphics.dataTableTrueTooltip')}</ListItemText>
                                    </MenuItem>
                                    <Divider sx={{ mb: 1, display: (tools.dataTable && tools.dialog) ? 'flex' : 'none' }} />
                                    <MenuItem
                                        sx={{ display: tools.dialog ? 'flex' : 'none' }}
                                        onClick={() => { handleTool('dialog', !toolsState.dialog.value, !toolsState.dialog.value, 'Visualización de ayuda e información', 'Clic en botón de ayuda e información'); handleClose() }}
                                    >
                                        <ListItemIcon>
                                            <InfoTwoTone color='primary' />
                                        </ListItemIcon>
                                        <ListItemText>{t('components.card.cardGraphics.dialog')}</ListItemText>
                                    </MenuItem>
                                </List>
                            </Menu>
                        </Stack>
                    }
                />
                <CardContent>
                    {validateEmptyData(processed) ? (
                        <CardEmpty />
                    ) : (
                        <Box sx={{ minHeight: 400 }}>{children}</Box>
                    )}
                </CardContent>
                <CardActions sx={{ display: tools.download ? 'flex' : 'none', mb: 2, justifyContent: 'flex-end' }}>
                    <Fab
                        variant='extended'
                        size='medium'
                        onClick={(event) => handleMenu(event, 'export')}
                        sx={{
                            borderRadius: 1,
                            zIndex: 0, mr: 1,
                            p: 1,
                            letterSpacing: 0.25,
                            boxShadow: '-4px 2px 47px -14px rgba(0,0,0,0.27)',
                            bgcolor: theme => theme.palette['green'].main,
                            color: 'white',
                            '&:hover': {
                                bgcolor: theme => theme.palette['green'].main,
                                opacity: 0.5,
                            },
                            '&:focus': {
                                outline: 'transparent',
                            },
                            '&:active': {
                                border: '3px solid white',
                            },
                        }}
                    >
                        <GetAppTwoTone sx={{ mr: 0.3 }} />
                        {t('components.card.cardGraphics.export')}
                    </Fab>
                    <Menu
                        disableScrollLock
                        anchorEl={menu.export}
                        open={Boolean(menu.export)}
                        onClose={() => handleClose('export')}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        PaperProps={{ style: { width: 200 } }}
                        sx={{ zIndex: 1 }}
                    >
                        <List>
                            <MenuItem onClick={() => handleDownload('image', null)}>
                                <ListItemIcon>
                                    <PermMediaTwoTone color='primary' />
                                </ListItemIcon>
                                <ListItemText>{t('components.card.cardGraphics.downloadImage')}</ListItemText>
                            </MenuItem>
                            <Divider sx={{ mb: 1 }} />
                            <MenuItem
                                sx={{ display: tools.dataTable ? 'flex' : 'none' }}
                                onClick={() => handleDownload('csv', { headers: columnsTable, rows: processed })}
                            >
                                <ListItemIcon>
                                    <FileOpenTwoTone color='primary' />
                                </ListItemIcon>
                                <ListItemText>{t('components.card.cardGraphics.downloadCsv')}</ListItemText>
                            </MenuItem>
                            <Divider sx={{ mb: 1, display: tools.dataTable ? 'flex' : 'none' }} />
                            <MenuItem onClick={() => handleDownload('print', null)}>
                                <ListItemIcon>
                                    <LocalPrintshopTwoTone color='primary' />
                                </ListItemIcon>
                                <ListItemText>{t('components.card.cardGraphics.print')}</ListItemText>
                            </MenuItem>
                        </List>
                    </Menu>
                </CardActions>
                <Collapse in={toolsState.dataTable.value} timeout='auto' unmountOnExit>
                    <CardContent id={title + 'table'}>
                        <DataTable
                            id='_id'
                            rows={processed}
                            columns={columnsTable}
                            sortModel={[{ field: infoChart.sortField || 'value', sort: 'desc' }]}
                            fileName={title.replaceAll(' ', '_')}
                        />
                    </CardContent>
                </Collapse>
            </Card>
            <Dialog
                _id={null}
                open={toolsState.dialog.value}
                title={title}
                width='md'
                handleClose={() => handleTool('dialog', !toolsState.dialog.value, !toolsState.dialog.value, 'Visualización de ayuda e información', 'Clic en botón de ayuda e información')}
                handleBack={{
                    active: false,
                    label: '',
                    function: null
                }}
                handleNext={{
                    active: false,
                    label: '',
                    function: () => handleTool('dialog', !toolsState.dialog.value, !toolsState.dialog.value, 'Visualización de ayuda e información', 'Clic en botón de ayuda e información')
                }}
            >
                <DialogContentText>{infoDialog.label}</DialogContentText>
                {infoDialog.gif && (
                    <CardMedia
                        component='img'
                        width='100%'
                        height='100%'
                        image={infoDialog.gif}
                        alt={title}
                        sx={{ mt: 2 }}
                    />
                )}
            </Dialog>
        </Box>
    );
}
CardGraphics.propTypes = {
    title: PropTypes.string,
    tools: PropTypes.shape({
        usability: PropTypes.bool,
        rangeTime: PropTypes.bool,
        gender: PropTypes.bool,
        type: PropTypes.bool,
        accumulate: PropTypes.bool,
        wordCloud: PropTypes.bool,
        typeChart: PropTypes.bool,
        download: PropTypes.bool,
        dialog: PropTypes.bool,
        dataTable: PropTypes.bool
    }),
    infoDialog: PropTypes.shape({
        label: PropTypes.string,
        gif: PropTypes.string,
        notice: PropTypes.string
    }),
    infoChart: PropTypes.shape({
        data: PropTypes.object,
        onChange: PropTypes.func,
        columns: PropTypes.object,
        formatValue: PropTypes.string,
        sortField: PropTypes.string
    }),
};
const mapStateToProps = (state) => ({
    investigation: state.investigation,
    extension: state.extension,
    settings: state.settings
});
const mapDispatchToProps = (dispatch) => ({
    setInvestigation(state) {
        dispatch({
            type: 'SET_INVESTIGATION',
            state,
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(CardGraphics);