import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { TabContext, TabPanel } from '@mui/lab';
import { Box, Container, Paper, Stack } from '@mui/material';
import { BorderColorTwoTone, ScreenSearchDesktopTwoTone, AddCircleTwoTone } from '@mui/icons-material';
import AppBar from '../../../../components/appbar/Appbar';
import Drawer from '../../../../components/Drawer';
import Navigation from '../../../../components/Navigation';
import Publications from './panels/Publications';
import Corrections from './panels/Corrections';
import { curatorDefault } from '../../../../../redux/store';
import { requestPost } from '../../../../../utils/request';
import { URL_API_INV } from '../../../../../constants';

function Curator(props) {
    const { login, settings, investigation, setInvestigation, setLogin } = props;
    const [stepTab, setStepTab] = useState(0);

    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const optionsNavigation = [
        {
            label: 'Publicaciones',
            icon: <ScreenSearchDesktopTwoTone />,
            display: true
        },
        {
            label: 'Publicaciones Corregidas',
            icon: <BorderColorTwoTone />,
            display: false
        },
        {
            label: 'Publicaciones Eliminadas',
            icon: <ScreenSearchDesktopTwoTone />,
            display: false
        },
        {
            label: 'Nueva publicación',
            icon: <AddCircleTwoTone />,
            display: true
        }
    ];

    const handleStep = (value) => {
        setLogin(curatorDefault);
        if (value === 3) {
            navigate(`/login/Inicio de sesión/curator/Curaduria/add/Nueva publicación`, { state: { key: 'hierarchy' } });
        } else {
            setStepTab(value);
        }
    };

    useEffect(() => {
        if (investigation.hierarchy.stepHome) {
            const keyDep = location.state?.key === 'hierarchy' ? false : true;
            window.history.replaceState({}, document.title);
            const hierarchy = {
                ...investigation.hierarchy,
                hierarchy: {
                    ...investigation.hierarchy.hierarchy,
                    dependence: [keyDep, 'Autenticación'],
                    hierarchy: ['Inicio de sesión', 'Curaduria'],
                },
                params: params,
                stepKey: true
            };
            setInvestigation({ hierarchy: hierarchy });
            requestPost(`${URL_API_INV}/usability/hierarchy`, { record: hierarchy });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [investigation.hierarchy.stepHome]);

    return (
        <Box>
            <Drawer view='login' data={login.admin.infoUser} />
            <Box
                component='main'
                sx={{
                    width: { sm: `calc(100% - ${settings.openDrawer ? settings.widthDrawer : 0}px)` },
                    ml: { sm: `${settings.openDrawer ? settings.widthDrawer : 0}px` }
                }}
            >
                <AppBar hierarchy='general' params={params} value={params.curator} />
                <Container sx={{ mt: 3, mb: 3 }}>
                    <Stack direction='column' sx={{ m: 3 }} spacing={3}>
                        <Paper elevation={4}>
                            <TabContext value={stepTab.toString()}>
                                <Navigation
                                    options={optionsNavigation}
                                    value={stepTab}
                                    handleStep={(newValue) => handleStep(newValue)}
                                />
                                <TabPanel value='0'>
                                    <Publications
                                        author={[]}
                                        order={{
                                            type: 'date',
                                            variant: 'dsc',
                                            fields: ['date', 'citations']
                                        }}
                                    />
                                </TabPanel>
                                <TabPanel value='1'>
                                    <Corrections
                                        author={[]}
                                        order={{
                                            type: 'date',
                                            variant: 'dsc',
                                            fields: ['date', 'citations']
                                        }}
                                        type={true}
                                    />
                                </TabPanel>
                                <TabPanel value='2'>
                                    <Corrections
                                        author={[]}
                                        order={{
                                            type: 'date',
                                            variant: 'dsc',
                                            fields: ['date', 'citations']
                                        }}
                                        type={false}
                                    />
                                </TabPanel>
                            </TabContext>
                        </Paper>
                    </Stack>
                </Container>
            </Box>
        </Box >
    );
};
const mapStateToProps = (state) => ({
    login: state.login,
    settings: state.settings,
    investigation: state.investigation,
});
const mapDispatchToProps = (dispatch) => ({
    setInvestigation(state) {
        dispatch({
            type: 'SET_INVESTIGATION',
            state,
        });
    },
    setLogin(state) {
        dispatch({
            type: 'SET_LOGIN',
            state,
        });
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(Curator);