import React from 'react';
import PropTypes from 'prop-types';
import { Treemap } from '@antv/g2plot';
import ReactG2Plot from 'react-g2plot';
import { configBoxesTree } from '../../../assets/theme/g2Theme';

function BoxesTree(props) {
    const { infoChart } = props;
    const data = {
        name: infoChart.name,
        children: infoChart.data
    };
    return (
        <ReactG2Plot
            Ctor={Treemap}
            options={{
                ...{
                    data: data,
                },
                ...configBoxesTree
            }}
        />
    );
}
BoxesTree.propTypes = {
    infoChart: PropTypes.shape({
        data: PropTypes.array
    })
};
export default BoxesTree;