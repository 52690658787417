import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { Grid, useMediaQuery } from '@mui/material';
import Snackbar from '../../components/Snackbar';
import Backdrop from '../../components/Backdrop';
import Feedback from '../dependence/feedback/Feedback';
import logoSVG from '../../../assets/images/unal/escudoUnal.svg';
import { formatMessage } from '../../../utils/utils';
import { requestGet, requestPost } from '../../../utils/request';
import { changeSizeLetter, changeContrasts, investColors, defaultConfig } from '../../../assets/js/accessibility';
import { URL_API_INV, URL_API_GIP, URL_FRO, ACT_DEP } from '../../../constants';

function Header(props) {
    const { investigation, settings, setDependences, setLogin, setInvestigation, setExtension, setMetricsDefault, setLoginDefault, setSettings } = props;
    const [routes, setRoutes] = useState({
        'inv': '/institution/Universidad Nacional de Colombia/resource/products',
        'ext': '/extension/Facultad de Ingeniería/resource/generalInformation'
    });

    const { t, i18n } = useTranslation();
    const params = useParams();
    const navigate = useNavigate();

    const isXs = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const handleRoute = (route) => {
        setMetricsDefault();
        setLoginDefault();
        navigate(route, { state: { key: 'init' } });
    };

    const changeLanguage = (value) => {
        setSettings({ language: value });
        i18n.changeLanguage(value);
    };

    useEffect(() => {
        Promise.all([
            requestGet(`${URL_API_GIP}`, {}, true),
            requestPost(`${URL_API_INV}/investigation/dependences`, {})
        ])
            .then(res => {
                const hierarchy = {
                    hierarchy: {
                        ...investigation.hierarchy.hierarchy,
                        init: 'Horus'
                    },
                    params: params,
                    infoClient: res[0].data,
                    stepHome: true
                };
                setDependences(res[1].data.dependences);
                setLogin({ news: { infoNews: res[1].data.news } });

                setInvestigation({ hierarchy: hierarchy });
                setExtension({ hierarchy: hierarchy });

                requestPost(`${URL_API_INV}/usability/init`, { record: hierarchy });
            })
            .catch(err => {
                setSettings({ snackbar: formatMessage(true, 'error', err) });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid>
            <Snackbar
                open={settings.snackbar.open}
                severity={settings.snackbar.severity}
                message={settings.snackbar.message}
            />
            <Backdrop
                open={settings.backdrop.open}
                message={settings.backdrop.message}
                position='fixed'
            />
            {ACT_DEP.FEE && (
                <Feedback />
            )}
            <div id='services'>
                <div className='indicator d-none d-md-block'></div>
                <ul className='dropdown-menu' id='servicios'>
                    <li>
                        <a
                            href='https://smartkey.xertica.com/cloudkey/a/unal.edu.co/user/login'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <img
                                src={require('../../../assets/images/unal/icnServEmail.png')}
                                width='32'
                                height='32'
                                alt='Correo Electrónico'
                            />
                            {t('header.email')}
                        </a>
                    </li>
                    <li>
                        <a
                            href='https://dninfoa.unal.edu.co'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <img
                                src={require('../../../assets/images/unal/icnServSia.png')}
                                width='32'
                                height='32'
                                alt='Dirección Nacional de Información Académica'
                            />
                            {t('header.sia')}
                        </a>
                    </li>
                    <li>
                        <a
                            href='https://bibliotecas.unal.edu.co'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <img
                                src={require('../../../assets/images/unal/icnServLibrary.png')}
                                width='32'
                                height='32'
                                alt='Biblioteca'
                            />
                            {t('header.libraries')}
                        </a>
                    </li>
                    <li>
                        <a
                            href='https://personal.unal.edu.co'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <img
                                src={require('../../../assets/images/unal/icnServCall.png')}
                                width='32'
                                height='32'
                                alt='Convocatorias'
                            />
                            {t('header.calls')}
                        </a>
                    </li>
                    <li>
                        <a
                            href='https://identidad.unal.edu.co'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <img
                                src={require('../../../assets/images/unal/icnServIdentidad.png')}
                                width='32'
                                height='32'
                                alt='Identidad UNAL'
                            />
                            {t('header.identity')}
                        </a>
                    </li>
                    <li style={{ display: ACT_DEP.INV ? 'block' : 'none' }}>
                        <a
                            className='hoverDecoration'
                            onClick={() => handleRoute(routes.inv)}
                        >
                            <img
                                src={require('../../../assets/images/horus/icnServCur.png')}
                                width='32'
                                height='32'
                                alt='Investigación'
                            />
                            {t('header.investigation')}
                        </a>
                    </li>
                    <li style={{ display: ACT_DEP.EXT ? 'block' : 'none' }}>
                        <a
                            className='hoverDecoration'
                            onClick={() => handleRoute(routes.ext)}
                        >
                            <img
                                src={require('../../../assets/images/horus/icnServCur.png')}
                                width='32'
                                height='32'
                                alt='extension'
                            />
                            {t('header.extensionFacultadIngenieria')}
                        </a>
                    </li>
                    <li style={{ display: ACT_DEP.AUT ? 'block' : 'none' }}>
                        <a
                            className='hoverDecoration'
                            onClick={() => handleRoute('/login/Inicio de sesión')}
                        >
                            <img
                                src={require('../../../assets/images/horus/icnServCur.png')}
                                width='32'
                                height='32'
                                alt='Inicio de sesión'
                            />
                            {t('header.login')}
                        </a>
                    </li>
                    <li style={{ display: ACT_DEP.REP ? 'block' : 'none' }}>
                        <a
                            className='hoverDecoration'
                            onClick={() => handleRoute('/reports/Reportes')}
                        >
                            <img
                                src={require('../../../assets/images/horus/icnServCur.png')}
                                width='32'
                                height='32'
                                alt='Reportes'
                            />
                            {t('header.reports')}
                        </a>
                    </li>
                </ul>
            </div>
            <header id='unalTop'>
                <div className='logo'>
                    <a href='https://unal.edu.co'>
                        <svg width='93%' height='93%'>
                            <image
                                xlinkHref={logoSVG}
                                width='100%'
                                height='100%'
                                className='hidden-print'
                                alt='Escudo de la Universidad Nacional de Colombia'
                            />
                        </svg>
                        <img
                            src={require('../../../assets/images/unal/escudoUnal_black.png')}
                            className='d-none d-print-block'
                            alt='Escudo de la Universidad Nacional de Colombia'
                        />
                        <img
                            src={require('../../../assets/images/unal/escudoUnal_black.png')}
                            className='d-none d-print-block'
                            alt='Escudo de la Universidad Nacional de Colombia'
                        />
                    </a>
                </div>
                <div className='seal'>
                    <img
                        className='hidden-print'
                        alt='Escudo de la República de Colombia'
                        src={require('../../../assets/images/unal/sealColombia.png')}
                        width='66'
                        height='66'
                    />
                    <img
                        className='d-none d-print-block'
                        alt='Escudo de la República de Colombia'
                        src={require('../../../assets/images/unal/sealColombia_black.png')}
                        width='66'
                        height='66'
                    />
                </div>
                <div className='firstMenu d-none d-md-block'>
                    <div className='content-fluid'>
                        <nav className='navbar navbar-expand-md nav navbar-dark'>
                            <div className='collapse navbar-collapse navbar-default' id='navbarSupportedContent'>
                                {/* <nav id='profiles'>
                                    <ul className='mr-auto nav justify-content-end'>
                                        <li className='nav-item item_Aspirantes #>'>
                                            <a href='https://aspirantes.unal.edu.co'>Aspirantes</a>
                                        </li>
                                        <li className='nav-item item_Estudiantes #>'>
                                            <a href='https://estudiantes.unal.edu.co'>Estudiantes</a>
                                        </li>
                                        <li className='nav-item item_Egresados #>'>
                                            <a href='https://egresados.unal.edu.co'>Egresados</a>
                                        </li>
                                        <li className='nav-item item_Docentes #>'>
                                            <a href='https://docentes.unal.edu.co'>Docentes</a>
                                        </li>
                                        <li className='nav-item item_Administrativos #>'>
                                            <a href='https://administrativos.unal.edu.co'>Administrativos</a>
                                        </li>
                                    </ul>
                                </nav> */}
                            </div>
                            {/* <ul className='socialLinks d-none d-md-block'>
                                <li>
                                    <a
                                        href='https://www.facebook.com/UNALOficial'
                                        target='_blank'
                                        rel='noreferrer'
                                        className='facebook'
                                        title='Página oficial en Facebook'
                                    />
                                </li>
                                <li>
                                    <a
                                        href='https://twitter.com/UNALOficial'
                                        target='_blank'
                                        rel='noreferrer'
                                        className='twitter'
                                        title='Cuenta oficial en Twitter'
                                    />
                                </li>
                                <li>
                                    <a
                                        href='https://www.youtube.com/channel/UCnE6Zj2llVxcvL5I38B0Ceg'
                                        target='_blank'
                                        rel='noreferrer'
                                        className='youtube'
                                        title='Canal oficial de Youtube'
                                    />
                                </li>
                            </ul> */}

                            <div className='collapse btn-group languageMenu d-none d-md-block'>
                                <div className='btn btn-default dropdown-toggle' data-bs-toggle='dropdown'>
                                    {settings.language.toUpperCase()}<span className='caret'></span>
                                </div>
                                <ul className='dropdown-menu dropdown-menu-right' id='language'>
                                    <li>
                                        <a className='language hoverDecoration' onClick={() => changeLanguage('es')}>
                                            ES - Español
                                        </a>
                                    </li>
                                    <li>
                                        <a className='language hoverDecoration' onClick={() => changeLanguage('en')}>
                                            EN - English
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
                <div id='bs-navbar' className='navigation d-none d-md-block'>
                    <div className='site-url' id='subdominio'>
                        <a href='https://horus.unal.edu.co/'>horus.unal.edu.co</a>
                    </div>
                    <div className='navbar-dark mainMenu' id='main_menu_container'>
                        <div className='btn-group pointer' style={{ display: ACT_DEP.INV ? 'inline-block' : 'none' }}>
                            <a
                                className='btn btn-default'
                                onClick={() => handleRoute(routes.inv)}
                            >
                                {t('header.investigation')}
                            </a>
                        </div>
                        <div className='btn-group' style={{ display: ACT_DEP.EXT ? 'inline-block' : 'none' }}>
                            <div className='btn btn-default dropdown-toggle' data-bs-toggle='dropdown'>
                                {t('header.extension')}<span className='caret'></span>
                            </div>
                            <ul className='dropdown-menu' id='extension'>
                                <li className='dropdown-item'>
                                    <a
                                        className='btn pointer'
                                        onClick={() => handleRoute(routes.ext)}
                                    >
                                        {t('header.extensionFacultadIngenieria')}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className='btn-group' style={{ display: ACT_DEP.EXT ? 'inline-block' : 'none' }}>
                            <div className='btn btn-default dropdown-toggle' data-bs-toggle='dropdown'>
                                {t('header.wiki')}<span className='caret'></span>
                            </div>
                            <ul className='dropdown-menu' id='extension'>
                                <li className='dropdown-item'>
                                    <a
                                        className='btn pointer'
                                        href={URL_FRO + '/wiki/'}
                                    >
                                        {t('Documentación')}
                                    </a>
                                </li>
                                <li className='dropdown-item'>
                                    <a
                                        className='btn pointer'
                                        href={URL_FRO + "/wiki/EquipoTrabajo"}
                                    >
                                        {t('Equipo de Trabajo y Colaboradores')}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className='btn-group menu_sedes'>
                            {/* <div className='btn btn-default dropdown-toggle' data-bs-toggle='dropdown'>
                                Sedes<span className='caret'></span>
                            </div>
                            <ul className='dropdown-menu' id='sedes'>
                                <li>
                                    <a className='dropdown-item' href='https://amazonia.unal.edu.co' target='_blank' rel='noreferrer'>Amazonia</a>
                                    <span className='caret-right'></span>
                                </li>
                                <li>
                                    <a className='dropdown-item' href='https://bogota.unal.edu.co' target='_blank' rel='noreferrer'>Bogotá</a>
                                    <span className='caret-right'></span>
                                </li>
                                <li>
                                    <a className='dropdown-item' href='https://caribe.unal.edu.co' target='_blank' rel='noreferrer'>Caribe</a>
                                    <span className='caret-right'></span>
                                </li>
                                <li>
                                    <a className='dropdown-item' href='https://delapaz.unal.edu.co' target='_blank' rel='noreferrer'>De La Paz</a>
                                    <span className='caret-right'></span>
                                </li>
                                <li>
                                    <a className='dropdown-item' href='https://www.manizales.unal.edu.co' target='_blank' rel='noreferrer'>Manizales</a>
                                    <span className='caret-right'></span>
                                </li>
                                <li>
                                    <a className='dropdown-item' href='https://medellin.unal.edu.co' target='_blank' rel='noreferrer'>Medellín</a>
                                    <span className='caret-right'></span>
                                </li>
                                <li>
                                    <a className='dropdown-item' href='https://orinoquia.unal.edu.co' target='_blank' rel='noreferrer'>Orinoquia</a>
                                    <span className='caret-right'></span>
                                </li>
                                <li>
                                    <a className='dropdown-item' href='https://www.palmira.unal.edu.co' target='_blank' rel='noreferrer'>Palmira</a>
                                    <span className='caret-right'></span>
                                </li>
                                <li>
                                    <a className='dropdown-item' href='https://tumaco-pacifico.unal.edu.co' target='_blank' rel='noreferrer'>Tumaco</a>
                                    <span className='caret-right'></span>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                </div>
                <nav className='navbar navbar-light light-blue lighten-4 main_menu'>
                    <div id='pestania-accesibilidad-mobil'>Panel de Accesibilidad</div>
                    <a className='navbar-brand d-block d-md-none' href='#'></a>
                    <button className='navbar-toggler collapsed d-block d-md-none' type='button' data-bs-toggle='collapse'
                        data-bs-target='#navbar_content' aria-controls='navbar_content' aria-expanded='false'
                        aria-label='Toggle navigation' id='btn_hamburguer'>
                        <span className='navbar-toggler-icon'></span>
                    </button>
                    <div className='collapse navbar-collapse' id='navbar_content'>
                        <div className='site-url' id='container_subdominio_mobil' style={{ width: '100%' }}></div>
                        <div id='container_mainmenu_mobil'>
                            <div className='btn-group pointer' style={{ display: ACT_DEP.INV ? 'inline-block' : 'none' }}>
                                <a
                                    className='btn btn-default'
                                    onClick={() => handleRoute(routes.inv)}
                                >
                                    {t('header.investigation')}
                                </a>
                            </div>
                            <div
                                className='btn-group d-block d-md-none hidden-print'
                                style={{ display: ACT_DEP.EXT ? 'inline-block' : 'none' }}
                            >
                                <div className='btn btn-default dropdown-toggle' data-bs-toggle='collapse'
                                    data-bs-target='#container_extension_mobil' aria-controls='container_extension_mobil'>
                                    {t('header.extension')}<span className='caret'> </span>
                                </div>
                            </div>
                            <div className='collapse' id='container_extension_mobil'>
                                <ul>
                                    <li className='btn-group d-block d-md-none hidden-print'>
                                        <a
                                            className='btn btn-default pointer'
                                            onClick={() => handleRoute(routes.ext)}
                                        >
                                            {t('header.extensionFacultadIngenieria')}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className='btn-group d-block d-md-none hidden-print' style={{ display: ACT_DEP.EXT ? 'inline-block' : 'none' }}>
                                <div className='btn btn-default dropdown-toggle' data-bs-toggle='collapse'
                                    data-bs-target='#container_wiki_mobil' aria-controls='container_wiki_mobil'>
                                    {t('header.wiki')}<span className='caret'></span>
                                </div>
                                <ul className='collapse' id='container_wiki_mobil'>
                                    <li className='btn-group d-block d-md-none hidden-print'>
                                        <a
                                            className='btn btn-default pointer'
                                            href={URL_FRO + '/wiki/'}
                                        >
                                            {t('Documentación')}
                                        </a>
                                    </li>
                                    <li className='btn-group d-block d-md-none hidden-print'>
                                        <a
                                            className='btn btn-default pointer'
                                            href={URL_FRO + "/wiki/EquipoTrabajo"}
                                        >
                                            {t('Equipo de Trabajo y Colaboradores')}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* <div className='btn-group d-block d-md-none hidden-print'>
                            <div className='btn btn-default dropdown-toggle' data-bs-toggle='collapse'
                                data-bs-target='#container_sedes_mobil' aria-controls='container_sedes_mobil'>
                                Sedes<span className='caret'> </span>
                            </div>
                        </div> */}
                        <div className='collapse' id='container_sedes_mobil'></div>
                        <div className='btn-group d-block d-md-none hidden-print'>
                            <div className='btn btn-default dropdown-toggle' data-bs-toggle='collapse'
                                data-bs-target='#container_language_mobil' aria-controls='container_language_mobil'>
                                {t('header.language')}<span className='caret'> </span>
                            </div>
                        </div>
                        <div className='collapse' id='container_language_mobil'>
                            <ul>
                                <li className='btn-group d-block d-md-none hidden-print'>
                                    <a
                                        className='btn btn-default pointer'
                                        onClick={() => changeLanguage('es')}
                                    >
                                        ES - Español
                                    </a>
                                </li>
                                <li className='btn-group d-block d-md-none hidden-print'>
                                    <a
                                        className='btn btn-default pointer'
                                        onClick={() => changeLanguage('en')}
                                    >
                                        EN - English
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className='btn-group d-block d-md-none hidden-print'>
                            <div className='btn btn-default dropdown-toggle' data-bs-toggle='collapse'
                                data-bs-target='#container_servicios_mobil' aria-controls='container_servicios_mobil'>
                                {t('header.services')}<span className='caret'> </span>
                            </div>
                        </div>
                        <div className='collapse' id='container_servicios_mobil'>
                            <ul>
                                <li>
                                    <a
                                        href='https://smartkey.xertica.com/cloudkey/a/unal.edu.co/user/login'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        <img
                                            src={require('../../../assets/images/unal/icnServEmail.png')}
                                            width='32'
                                            height='32'
                                            alt='Correo Electrónico'
                                        />
                                        {t('header.email')}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href='https://dninfoa.unal.edu.co'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        <img
                                            src={require('../../../assets/images/unal/icnServSia.png')}
                                            width='32'
                                            height='32'
                                            alt='Dirección Nacional de Información Académica'
                                        />
                                        {t('header.sia')}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href='https://bibliotecas.unal.edu.co'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        <img
                                            src={require('../../../assets/images/unal/icnServLibrary.png')}
                                            width='32'
                                            height='32'
                                            alt='Biblioteca'
                                        />
                                        {t('header.libraries')}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href='https://personal.unal.edu.co'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        <img
                                            src={require('../../../assets/images/unal/icnServCall.png')}
                                            width='32'
                                            height='32'
                                            alt='Convocatorias'
                                        />
                                        {t('header.calls')}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href='https://identidad.unal.edu.co'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        <img
                                            src={require('../../../assets/images/unal/icnServIdentidad.png')}
                                            width='32'
                                            height='32'
                                            alt='Identidad UNAL'
                                        />
                                        {t('header.identity')}
                                    </a>
                                </li>
                                <li style={{ display: ACT_DEP.INV ? 'block' : 'none' }}>
                                    <a
                                        className='pointer'
                                        onClick={() => handleRoute(routes.inv)}
                                    >
                                        <img
                                            src={require('../../../assets/images/horus/icnServCur.png')}
                                            width='32'
                                            height='32'
                                            alt='Investigación'
                                        />
                                        {t('header.investigation')}
                                    </a>
                                </li>
                                <li style={{ display: ACT_DEP.EXT ? 'block' : 'none' }}>
                                    <a
                                        className='pointer'
                                        onClick={() => handleRoute(routes.ext)}
                                    >
                                        <img
                                            src={require('../../../assets/images/horus/icnServCur.png')}
                                            width='32'
                                            height='32'
                                            alt='extension'
                                        />
                                        {t('header.extensionFacultadIngenieria')}
                                    </a>
                                </li>
                                <li style={{ display: ACT_DEP.AUT ? 'block' : 'none' }}>
                                    <a
                                        className='pointer'
                                        onClick={() => handleRoute('/login/Inicio de sesión')}
                                    >
                                        <img
                                            src={require('../../../assets/images/horus/icnServCur.png')}
                                            width='32'
                                            height='32'
                                            alt='Inicio de sesión'
                                        />
                                        {t('header.login')}
                                    </a>
                                </li>
                                <li style={{ display: ACT_DEP.REP ? 'block' : 'none' }}>
                                    <a
                                        className='pointer'
                                        onClick={() => handleRoute('/reports/Reportes')}
                                    >
                                        <img
                                            src={require('../../../assets/images/horus/icnServCur.png')}
                                            width='32'
                                            height='32'
                                            alt='Reportes'
                                        />
                                        {t('header.reports')}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        {/* <div className='btn-group d-block d-md-none hidden-print'>
                            <div className='btn btn-default dropdown-toggle' data-bs-toggle='collapse'
                                data-bs-target='#container_profiles_mobil' aria-controls='container_profiles_mobil'>
                                Perfiles<span className='caret'> </span>
                            </div>
                        </div> */}
                        <div className='collapse' id='container_profiles_mobil'></div>
                    </div>
                </nav>
            </header>
            <div
                id='tx-unal-accesibilidad'
                className='tx-unal-accesibilidad'
                style={{ position: 'absolute', top: settings.heightTop, zIndex: 10 }}
            >
                <div
                    id='panel-accesibilidad'
                    className='panel-content container-fluid'
                    style={{ display: 'none' }}
                >
                    <div className='col-md-12'>
                        <div className='row'>
                            <div className='col-md-3'>
                                <h4>{t('header.letterSize')}</h4>
                                <button
                                    className='boton-panel' id='letra-disminuir'
                                    onClick={() => changeSizeLetter('-')}
                                    type='submit' name='' value=''
                                >
                                    A<sup>-</sup>
                                </button>
                                <button
                                    className='boton-panel' id='letra-aumentar'
                                    onClick={() => changeSizeLetter('+')}
                                    type='submit' name='' value=''
                                >
                                    A<sup>+</sup>
                                </button>
                                <input disabled='1' className='letras-porcentaje' id='letter-percent' type='text' name='' value='100%' />
                            </div>
                            <div className='col-md-3'>
                                <h4>{t('header.changeContrasts')}</h4>
                                <button
                                    className='boton-panel' id='contraste-1'
                                    onClick={() => changeContrasts(1)}
                                    type='submit'
                                    name='' value=''
                                >
                                    1
                                </button>
                                <button
                                    className='boton-panel' id='contraste-2'
                                    onClick={() => changeContrasts(2)}
                                    type='submit'
                                    name='' value=''
                                >
                                    2
                                </button>
                                <button
                                    className='boton-panel' id='contrate-3'
                                    onClick={() => changeContrasts(3)}
                                    type='submit'
                                    name='' value=''
                                >
                                    3
                                </button>
                            </div>
                            <div className='col-md-3'>
                                <h4>{t('header.invertColors')}</h4>
                                <button
                                    className='boton-panel' id='inversor'
                                    onClick={() => investColors()}
                                    type='submit' name=''
                                    value=''
                                >
                                    {t('header.apply')}
                                </button>
                            </div>
                            <div className='col-md-3'>
                                <h4>{t('header.resetSettings')}</h4>
                                <button
                                    className='boton-panel' id='defaul-config'
                                    onClick={() => defaultConfig()}
                                    type='submit'
                                    name='' value=''>
                                    {t('header.apply')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    id='pestania-accesibilidad'
                    style={{ display: isXs ? 'none' : 'block' }}
                >
                    {t('header.accessibility')}
                </div>
            </div>
        </Grid >
    );
}
const mapStateToProps = (state) => ({
    investigation: state.investigation,
    settings: state.settings,
});
const mapDispatchToProps = (dispatch) => ({
    setDependences(state) {
        dispatch({
            type: 'SET_DEPENDENCE',
            state,
        });
    },
    setLogin(state) {
        dispatch({
            type: 'SET_LOGIN',
            state,
        });
    },
    setInvestigation(state) {
        dispatch({
            type: 'SET_INVESTIGATION',
            state,
        });
    },
    setExtension(state) {
        dispatch({
            type: 'SET_EXTENSION',
            state,
        });
    },
    setMetricsDefault() {
        dispatch({
            type: 'SET_METRICS_DEFAULT'
        });
    },
    setLoginDefault() {
        dispatch({
            type: 'SET_LOGIN_DEFAULT'
        });
    },
    setSettings(state) {
        dispatch({
            type: 'SET_SETTINGS',
            state,
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);