import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Paper, Box, Avatar, InputBase, IconButton, Divider } from '@mui/material';
import { Search } from '@mui/icons-material';
import Tooltip from '../Tooltip';

function SearchTopics(props) {
    const { hierarchy, params, value, setMetricsDefault } = props;
    const [search, setSearch] = useState({
        gate: true,
        value: value,
        default: value
    });

    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleChange = (event, field, value) => {
        setSearch({ ...search, [field]: value });
    };

    const handleRoute = (value) => {
        if (search.default !== value) {
            setMetricsDefault();
            setSearch({...search, default: value});
            const route = `/search/${hierarchy}/query/${value}/resource/${params.resource || 'products'}`;
            navigate(route, { state: { key: 'hierarchy', gate: search.gate } });
        }
    };

    return (
        <Box>
            <Paper
                elevation={10}
                sx={{ display: 'flex', width: '100%', height: 36, borderRadius: 1, borderTopLeftRadius: 0 }}
            >
                <InputBase
                    value={search.value}
                    onChange={(event) => handleChange(event, 'value', event.target.value)}
                    onKeyUp={(event) => event.code === 'Enter' ? handleRoute(event.target.value) : null}
                    placeholder={t('components.search.SearchTopics.placeholder')}
                    sx={{ flex: 1, ml: 3, fontSize: 16, fontWeight: 500 }}
                />
                <Tooltip title={t('components.search.SearchTopics.tooltip')}>
                    <IconButton
                        sx={{
                            p: '10px',
                            '&:focus': {
                                outline: 'transparent',
                            },
                        }}
                        onClick={() => search.value === '' ? null : handleRoute(search.value)}
                    >
                        <Search />
                    </IconButton>
                </Tooltip>
                <Divider sx={{ height: 30, m: 0.3 }} orientation='vertical' />
                <Tooltip
                    title={
                        [
                            t('components.search.SearchTopics.gateInfo'), search.gate ? 'AND' : 'OR',
                            t('components.search.SearchTopics.gateTo'), search.gate ? 'OR' : 'AND'
                        ].join(' ')
                    }
                >
                    <IconButton
                        sx={{
                            p: '10px',
                            '&:focus': {
                                outline: 'transparent',
                            },
                        }}
                        onClick={(event) => handleChange(event, 'gate', !search.gate)}
                    >
                        <Avatar
                            sx={{
                                width: 24, height: 24, fontSize: 10,
                                bgcolor: theme => theme.palette.info.main,
                                '&:focus': {
                                    outline: 'transparent',
                                },
                            }}
                        >
                            {search.gate ? 'AND' : 'OR'}
                        </Avatar>
                    </IconButton>
                </Tooltip>
            </Paper>
        </Box>
    );
}
SearchTopics.propTypes = {
    hierarchy: PropTypes.string,
    params: PropTypes.object,
    value: PropTypes.string
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
    setMetricsDefault() {
        dispatch({
            type: 'SET_METRICS_DEFAULT'
        });
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(SearchTopics);