import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Stack } from '@mui/material';
import CardGraphics from '../../../../components/card/CardGraphics';
import WordCloud from '../../../../components/graphics/WordCloud';
import Graph3D from '../../../../components/graphics/Graph3D';
import LDAvis from '../../../../components/graphics/LDAvis';
import { requestPost } from '../../../../../utils/request';
import { metricsInv, formatMessage, objectAssignInv } from '../../../../../utils/utils';
import { constants, URL_API_INV } from '../../../../../constants';
import { optionsWordCloudLanguage } from '../../../../../redux/store';

function Areas(props) {
    const { investigation, settings, setInvestigation, setSettings } = props;

    const { t } = useTranslation();
    const activeHierarchy = investigation.hierarchy.hierarchy.hierarchy[2];

    const handleOnChange = (value, state) => {
        const newInvestigation = objectAssignInv(investigation, {
            metrics: { areas: { [state]: value } }
        });
        newInvestigation.filters.rangeTime.type = true;
        setInvestigation(
            metricsInv(
                [state],
                investigation.metrics.areas.source,
                investigation.hierarchy,
                newInvestigation,
                settings
            )
        );
    };

    useEffect(() => {
        setSettings({ backdrop: formatMessage(true, '', t('dependence.investigation.consultingInfoWorkAreas')) });
        if (investigation.hierarchy.metrics.areas) {
            const hierarchy = {
                ...investigation.hierarchy,
                resource: 'Temáticas y Areas de trabajo'
            };
            Promise.all([
                requestPost(`${URL_API_INV}/investigation/areas_metrics`, {
                    _id: investigation.hierarchy.metrics.areas,
                    record: hierarchy
                }),
            ])
                .then(res => {
                    setInvestigation(
                        metricsInv(
                            Object.keys(investigation.metrics.areas),
                            res[0].data,
                            hierarchy,
                            investigation,
                            settings
                        )
                    );
                    setSettings({ backdrop: formatMessage(false, '', '') });
                })
                .catch(err => {
                    setSettings({
                        backdrop: formatMessage(false, '', ''),
                        snackbar: formatMessage(true, 'error', err),
                    });
                });
        }
        let value = investigation.metrics.areas[constants.METRIC_WORDCLOUD_FIELD];
        value.tools.wordCloudLanguage.value = settings.language === 'es' ? optionsWordCloudLanguage[0] : optionsWordCloudLanguage[1];
        handleOnChange(value, 'wordcloud');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [investigation.hierarchy.metrics.areas, settings.language]);

    return (
        <Box>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
                <CardGraphics
                    title={t('dependence.investigation.wordCloud')}
                    tools={{
                        usability: true,
                        rangeTime: false,
                        gender: false,
                        type: false,
                        accumulate: false,
                        wordCloud: true,
                        download: true,
                        dialog: true,
                        dataTable: true
                    }}
                    infoDialog={{
                        label: t('dependence.investigation.workCloudInfo'),
                        gif: require('../../../../../assets/images/animation/nube.gif'),
                        notice: ''
                    }}
                    infoChart={{
                        data: investigation.metrics.areas[constants.METRIC_WORDCLOUD_FIELD],
                        onChange: (value) => handleOnChange(value, constants.METRIC_WORDCLOUD_FIELD),
                        columns: {
                            name: t('dependence.investigation.areasMain.wordCloudType'),
                            value: t('dependence.investigation.areasMain.wordCloudValue'),
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <WordCloud
                        infoChart={{
                            data: investigation.metrics.areas[constants.METRIC_WORDCLOUD_FIELD].processed.slice(0, 300),
                        }}
                    />
                </CardGraphics>
            </Stack>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}
                sx={{
                    mt: 3,
                    display: activeHierarchy === 'DEPENDENCE_FACULTY_FIELD' || activeHierarchy === 'DEPENDENCE_UAB_FIELD' ? 'block' : 'none'
                }}
            >
                <CardGraphics
                    title={t('dependence.investigation.themeGraph')}
                    tools={{
                        usability: true,
                        rangeTime: false,
                        gender: false,
                        type: false,
                        accumulate: false,
                        wordCloud: false,
                        download: true,
                        dialog: false,
                        dataTable: false
                    }}
                    infoDialog={{
                        label: '',
                        gif: '',
                        notice: ''
                    }}
                    infoChart={{
                        data: investigation.metrics.areas[constants.METRIC_GRAPH_KEYWORDS_FIELD],
                        onChange: (value) => handleOnChange(value, constants.METRIC_GRAPH_KEYWORDS_FIELD),
                        columns: {
                            type: '',
                            value: '',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <Graph3D
                        infoChart={{
                            data: investigation.metrics.areas[constants.METRIC_GRAPH_KEYWORDS_FIELD].processed,
                            filter: false
                        }}
                    />
                </CardGraphics>
            </Stack>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} sx={{ mt: 3 }}>
                <CardGraphics
                    title={t('dependence.investigation.lda')}
                    tools={{
                        usability: true,
                        rangeTime: false,
                        gender: false,
                        type: false,
                        accumulate: false,
                        wordCloud: false,
                        download: true,
                        dialog: false,
                        dataTable: false
                    }}
                    infoDialog={{
                        label: '',
                        gif: '',
                        notice: ''
                    }}
                    infoChart={{
                        data: investigation.metrics.areas[constants.METRIC_LDA_FIELD],
                        onChange: (value) => handleOnChange(value, constants.METRIC_LDA_FIELD),
                        columns: {
                            type: '',
                            value: '',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <LDAvis
                        infoChart={{
                            data: investigation.metrics.areas[constants.METRIC_LDA_FIELD].processed
                        }}
                    />
                </CardGraphics>
            </Stack>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}
                sx={{
                    mt: 3,
                    display: activeHierarchy === 'DEPENDENCE_AUTHOR_FIELD' ? 'block' : 'none'
                }}
            >
                <CardGraphics
                    title={t('dependence.investigation.collaborations')}
                    tools={{
                        usability: true,
                        rangeTime: false,
                        gender: false,
                        type: false,
                        accumulate: false,
                        wordCloud: false,
                        download: true,
                        dialog: false,
                        dataTable: false
                    }}
                    infoDialog={{
                        label: '',
                        gif: '',
                        notice: ''
                    }}
                    infoChart={{
                        data: investigation.metrics.areas[constants.METRIC_GRAPH_FIELD],
                        onChange: (value) => handleOnChange(value, constants.METRIC_GRAPH_FIELD),
                        columns: {
                            type: '',
                            value: '',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <Graph3D
                        infoChart={{
                            data: investigation.metrics.areas[constants.METRIC_GRAPH_FIELD].processed,
                            filter: true
                        }}
                    />
                </CardGraphics>
            </Stack>
        </Box>
    )
}
const mapStateToProps = (state) => ({
    investigation: state.investigation,
    settings: state.settings
});
const mapDispatchToProps = (dispatch) => ({
    setInvestigation(state) {
        dispatch({
            type: 'SET_INVESTIGATION',
            state,
        });
    },
    setSettings(state) {
        dispatch({
            type: 'SET_SETTINGS',
            state,
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(Areas);