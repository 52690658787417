import React from 'react';
import PropTypes from 'prop-types';
import { Line } from '@antv/g2plot';
import ReactG2Plot from 'react-g2plot';
import { configMultiLine, configColorGender } from '../../../assets/theme/g2Theme';

function MultiLine(props) {
    const { infoChart } = props;
    return (
        <ReactG2Plot
            Ctor={Line}
            options={{
                ...{
                    data: infoChart.data,
                    xAxis: { title: { text: infoChart.axis.xAxis } },
                    yAxis: { title: { text: infoChart.axis.yAxis } },
                    ...(infoChart.colorGender ? configColorGender : {}),
                },
                ...configMultiLine,
            }}
        />
    );
}
MultiLine.propTypes = {
    infoChart: PropTypes.shape({
        data: PropTypes.array,
        colorGender: PropTypes.bool,
        axis: PropTypes.object,
    }),
};
export default MultiLine;
