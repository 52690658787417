import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, Avatar, Stack, Divider, Typography, IconButton, MenuList, MenuItem, ListItemIcon, ListItemText, Box } from '@mui/material';
import { MarkEmailReadTwoTone, PersonSearchTwoTone, Person3TwoTone, PersonOutlineTwoTone } from '@mui/icons-material';
import Tooltip from '../Tooltip';
import { constants } from '../../../constants';

function CardAuthor(props) {
    const { author } = props;
    const { t } = useTranslation();

    const platforms = [
        {
            key: constants.AUTHOR_URL_RG_FIELD,
            label: 'ResearchGate',
            icon: require('../../../assets/images/horus/researchgate.png')
        },
        {
            key: constants.AUTHOR_URL_GS_FIELD,
            label: 'Google Scholar',
            icon: require('../../../assets/images/horus/scholar.png')
        },
        {
            key: constants.AUTHOR_URL_CVLAC_FIELD,
            label: 'CvLac',
            icon: require('../../../assets/images/horus/cvlac.png')
        },
        {
            key: constants.AUTHOR_URL_ORCID_FIELD,
            label: 'ORCiD',
            icon: require('../../../assets/images/horus/orcid.png')
        }
    ];
    const information = [
        {
            key: constants.AUTHOR_EMAIL_FIELD,
            label: t('components.card.cardAuthor.email'),
            icon: <MarkEmailReadTwoTone />
        },
        {
            key: constants.AUTHOR_STATE_FIELD,
            label: t('components.card.cardAuthor.bonding'),
            icon: <PersonSearchTwoTone />
        }
    ];
    const indices = [
        {
            key: constants.AUTHOR_H_INDEX_GS_FIELD,
            label: 'Google Scholar',
            icon: require('../../../assets/images/horus/scholar.png')
        },
        {
            key: constants.AUTHOR_H_INDEX_SCOPUS_FIELD,
            label: 'Scopus',
            icon: require('../../../assets/images/horus/scopus.png')
        }
    ];

    return (
        <Card
            elevation={4}
            sx={{
                borderRadius: 1,
                bgcolor: 'rgba(218, 218, 218, 0.2)',
                backdropFilter: 'blur(6px)',
                color: 'white',
                textShadow: '0 0 2px rgba(0, 0, 0, 0.3)',
                pt: 3, pb: 3, pl: 6, pr: 5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: '100%',
                height: { sm: 300 }
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    textAlign: { xs: 'center', sm: 'left' },
                    alignItems: 'center',
                    minWidth: '100%'
                }}
            >
                <Stack sx={{
                    maxWidth: '100%',
                    pr: { md: 0, lg: 6 },
                    alignItems: { xs: 'center', sm: 'flex-start' }
                }}>
                    <Avatar
                        alt='image'
                        sx={{
                            mb: 2,
                            width: 130,
                            height: 130,
                            bgcolor: theme => theme.palette[author[constants.AUTHOR_GENDER_FIELD] === 'F'
                                ? 'female'
                                : author[constants.AUTHOR_GENDER_FIELD] === 'M'
                                    ? 'male'
                                    : 'info'].main,
                        }}
                    >
                        {author[constants.AUTHOR_IMAGE_FIELD] ? (
                            <Box
                                component='img'
                                width='100%'
                                height='100%'
                                src={
                                    author[constants.AUTHOR_IMAGE_FIELD]
                                        ? author[constants.AUTHOR_IMAGE_FIELD].includes('http')
                                            ? author[constants.AUTHOR_IMAGE_FIELD]
                                            : require('../../../assets/images/' + author[constants.AUTHOR_IMAGE_FIELD])
                                        : null}
                            />
                        ) : (
                            <Box
                                component={
                                    author[constants.AUTHOR_GENDER_FIELD] === 'F'
                                        ? Person3TwoTone
                                        : PersonOutlineTwoTone
                                }
                                fontSize='70px'
                            />
                        )}
                    </Avatar>
                    <Typography variant='h4' sx={{ lineHeight: 1, fontWeight: 800 }}>
                        {author[constants.AUTHOR_NAME_FIELD]}
                    </Typography>
                    <Typography variant='body0' sx={{ pt: 1, lineHeight: 1, mb: 1 }}>
                        {t(`components.card.cardAuthor.${author[constants.AUTHOR_POSITION_FIELD]}`)} - {t(`components.card.cardAuthor.${author[constants.AUTHOR_CONTRACT_FIELD]}`)}
                    </Typography>
                    <MenuList sx={{ p: { xs: 0, sm: 1 } }}>
                        {information.map(info =>
                            (info.key in author) &&
                            <Tooltip key={info.key} title={info.label + t('components.card.cardAuthor.tooltipInfo')}>
                                <MenuItem sx={{ cursor: 'initial', m: { xs: -2, sm: 0 }, p: 0 }}>
                                    <ListItemIcon sx={{ color: 'white', minWidth: 'auto' }}>
                                        {info.icon}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography variant='body1' sx={{ whiteSpace: 'nowrap' }} noWrap>
                                                {author[info.key]}
                                            </Typography>
                                        }
                                    />
                                </MenuItem>
                            </Tooltip>
                        )}
                    </MenuList>
                </Stack>
                <Stack sx={{
                    p: 1,
                    minHeight: { sm: 280 },
                    ml: { sm: 'auto' },
                    justifyContent: 'space-between',
                    alignItems: { xs: 'center', sm: 'flex-start' }
                }}>
                    <Box sx={{ mt: { xs: 2, sm: 5 } }}>
                        <Typography variant='h5' sx={{ textAlign: 'center', width: '100%', mb: 1 }}>
                            {t('components.card.cardAuthor.hIndex')}
                        </Typography>
                        <Stack
                            direction='row'
                            spacing={2}
                            divider={
                                <Divider
                                    orientation='vertical'
                                    flexItem
                                    sx={{ bgcolor: 'white', width: '2px' }}
                                />
                            }
                        >
                            {indices.map(indice =>
                                <Box key={indice.key}
                                    sx={{
                                        textAlign: 'center',
                                    }}
                                >
                                    <Typography variant='h2' sx={{ lineHeight: 1 }}>
                                        {author[indice.key] !== 'No Reportado' ? author[indice.key] : 0}
                                    </Typography>
                                    <Typography variant='subtitle'>
                                        {indice.label}
                                    </Typography>
                                </Box>
                            )}
                        </Stack>
                    </Box>
                    <Box>
                        <Typography variant='h5' sx={{ pt: 2, lineHeight: 1, pb: 0.5 }}>
                            {t('components.card.cardAuthor.profiles')}
                        </Typography>
                        <Stack direction='row' spacing={1} sx={{ pb: { sm: 2 } }}>
                            {platforms.map(platform => (
                                author[platform.key].map((item, index) => {
                                    return (
                                        <Tooltip
                                            key={platform.key + index}
                                            title={t('components.card.cardAuthor.tooltipPlat') + platform.label}
                                        >
                                            <IconButton
                                                href={item}
                                                target='_blank'
                                                rel='noreferrer'
                                                sx={{ p: 0 }}
                                            >
                                                <Avatar
                                                    alt={platform.label}
                                                    src={platform.icon}
                                                    sx={{ width: 30, height: 30 }}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    )
                                })
                            ))}
                        </Stack>
                    </Box>
                </Stack>
            </Box>
        </Card>
    );
};
CardAuthor.propTypes = {
    author: PropTypes.object
};
export default CardAuthor;