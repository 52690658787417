import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import ExcelJS from 'exceljs';
import { Stack, Grid, Typography, Avatar, Box, Chip, IconButton, Card, CardHeader, CardContent, TextField, FormControl, InputLabel, Select, MenuItem, Button, TablePagination, Autocomplete, InputAdornment, OutlinedInput } from '@mui/material';
import { DownloadForOfflineTwoTone, FormatQuoteTwoTone, PaidTwoTone, MonitorHeartTwoTone, BorderColorTwoTone, LaunchTwoTone, CalendarMonthTwoTone, ArrowUpwardTwoTone, ArrowDownwardTwoTone, LanguageTwoTone, EditOffTwoTone, Diversity3TwoTone, HourglassTopTwoTone, SearchTwoTone } from '@mui/icons-material';
import CardEmpty from './CardEmpty';
import CardAuthorList from './CardAuthorList';
import Tooltip from '../Tooltip';
import Scrollbar from '../Scrollbar';
import MathText from '../MathText';
import TreeSearch from '../tree/TreeSearch';
import Dialog from '../Dialog';
import { getOptionsAuthor, formatDate, formatDateLarge, formatDateExt, getOptionsAuthorValue, getParams, getParamsRouteString, formatText, formatDownload, formatCurrency, formatCapitalize, sortedArray, formatNumberFloat } from '../../../utils/utils';
import { constants } from '../../../constants';

function CardProductsList(props) {
    const { data, params, hierarchy, options, handleFilter, dependence, settings, setMetricsDefault } = props;

    const [productList, setProductList] = useState({
        dialog: false,
        page: 0,
        rowsPage: options.rowsPage,
        contentSearch: '',
        authorSearch: '',
        authorOption: [],
        authorOptions: [],
        authorOptionsInit: [],
        typeOption: [],
        typeOptions: [],
        order: {
            type: options.order.type,
            variant: options.order.variant
        }
    });

    const navigate = useNavigate();
    const { t } = useTranslation();

    const activeDependence = hierarchy.hierarchy.dependence[1] === 'Autenticación'
        ? 'Investigación'
        : hierarchy.hierarchy.dependence[1] || 'Investigación';

    const getFilter = (fields, complete) => {
        const filters = ['page', 'rowsPage', 'contentSearch', 'authorOption', 'typeOption', 'order'];
        return Object.fromEntries([
            ...filters.map(key => key in fields
                ? [key, fields[key]]
                : [key, key === 'authorOption'
                    ? productList[key].map(item => item.value)
                    : productList[key]
                ]
            ),
            ['complete', complete]
        ]);
    };

    const handleRecord = (filter, type, value) => {
        const record = type === 'none' ? 'none' : {
            ...hierarchy,
            subResource: 'Lista de Productos',
            action: { type: type, value: value }
        };
        handleFilter(filter, record, 'state');
    };

    const handlePage = (event, value) => {
        const filter = getFilter({ page: value }, false);
        handleRecord(filter, 'Cambio en paginación', value);
        setProductList(prevState => ({ ...prevState, page: value }));
    };

    const handleRowsPage = (event) => {
        const value = parseInt(event.target.value, 10);
        const filter = getFilter({ rowsPage: value, page: 0 }, false);
        handleRecord(filter, 'Cambio en número de publicaciones por pagina', value);
        setProductList(prevState => ({ ...prevState, rowsPage: value, page: 0 }));
    };

    const handleSearch = (value) => {
        const filter = getFilter({ contentSearch: value, page: 0 }, false);
        handleRecord(filter, 'none', value);
        setProductList(prevState => ({ ...prevState, contentSearch: value, page: 0 }));
    };

    const handleAuthor = (event, data) => {
        const value = getOptionsAuthorValue(data, event.type);
        const filter = getFilter({ authorOption: value.map(item => item.value), page: 0 }, false);
        handleRecord(filter, 'Cambio en autor', value.map(item => item.label).join(' - '));
        setProductList(prevState => ({ ...prevState, authorOption: value, page: 0 }));
    };

    const handleType = (event, value) => {
        const filter = getFilter({ typeOption: value.length > 0 ? value : [1], page: 0 }, false);
        handleRecord(filter, 'Cambio en tipo de productos', value);
        setProductList(prevState => ({ ...prevState, typeOption: value, page: 0 }));
    };

    const handleOrderType = (event) => {
        const orderType = {
            relevance: 'Ordenar por relevancia',
            date: 'Ordenar por fecha',
            citations: 'Ordenar por citaciones',
            value: 'Ordenar por valor'
        };
        const value = event.target.value;
        const filter = getFilter({ order: { type: value, variant: productList.order.variant } }, false);
        handleRecord(filter, 'Ordenar por', orderType[value]);
        setProductList(prevState => ({ ...prevState, order: { type: value, variant: prevState.order.variant } }));
    };

    const handleOrderVariant = (value) => {
        const filter = getFilter({ order: { type: productList.order.type, variant: value } }, false);
        handleRecord(filter, 'Ordenar por ascendente o descendente', value === 'asc' ? 'Ascendente' : 'Descendente');
        setProductList(prevState => ({ ...prevState, order: { type: prevState.order.type, variant: value } }));
    };

    const handleUrl = (url) => {
        handleRecord({}, 'Visualizar publicación en enlace externo (URL)', url);
    };

    const handleDialog = () => {
        if (!productList.dialog) {
            if (
                options.total > productList.rowsPage ||
                productList.contentSearch !== '' ||
                productList.authorOption.length !== productList.authorOptionsInit.length ||
                productList.typeOption.length !== productList.typeOptions.length ||
                productList.page !== 0
            ) {
                setProductList(prevState => ({ ...prevState, dialog: true }));
            } else {
                handleDownload('all')
            }
        } else {
            setProductList(prevState => ({ ...prevState, dialog: false }));
        }
    };

    const handleDownload = async (type) => {
        setProductList(prevState => ({ ...prevState, dialog: false }));
        let download = [];
        const record = {
            ...hierarchy,
            subResource: 'Lista de Productos',
            action: { type: 'Descargar en formato csv.', value: 'Descarga de archivo csv.' }
        };
        if (type === 'all') {
            const limit = 25000;
            const total = options.total;
            const pages = Math.ceil(total / limit);
            for (let page = 0; page < pages; page++) {
                const filter = getFilter({
                    contentSearch: '',
                    authorOption: [],
                    typeOption: [],
                    page: page,
                    rowsPage: limit
                }, true);
                const dataFilter = await handleFilter(filter, record, 'return');
                download = download.concat(dataFilter);
            }
        } else {
            const filter = getFilter({}, true);
            const dataFilter = await handleFilter(filter, record, 'return');
            download = dataFilter;
        }
        if (download) {
            const value = formatDownload(download);
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Lista de Productos');
            worksheet.columns = activeDependence === 'Investigación' ? [
                { header: 'Título original', key: constants.PUBLICATION_TITLE_FIELD },
                { header: 'Descripción original', key: constants.PUBLICATION_ABSTRACT_FIELD },
                { header: 'Revista / Conferencia', key: constants.PUBLICATION_PUBNAME_FIELD },
                { header: 'Coautores', key: constants.PUBLICATION_AUTHORS_FIELD },
                { header: 'Doi', key: constants.PUBLICATION_DOI_FIELD },
                { header: 'ISBN', key: constants.PUBLICATION_ISBN_FIELD },
                { header: 'ISSN', key: constants.PUBLICATION_ISSN_FIELD },
                { header: 'Citaciones', key: constants.PUBLICATION_CITATION_FIELD },
                { header: 'Idioma', key: constants.PUBLICATION_LANGUAGE_FIELD },
                { header: 'Fecha', key: constants.PUBLICATION_DATE_FIELD },
                { header: 'Tipo', key: constants.PUBLICATION_SOURCE_TYPE_FIELD },
                { header: 'Fuente', key: constants.PUBLICATION_SOURCE_FIELD },
                { header: 'Enlace', key: constants.PUBLICATION_URL_FIELD },
            ] : [
                { header: 'Entidad contratante', key: constants.PUBLICATION_TITLE_FIELD },
                { header: 'Modalidad', key: constants.PUBLICATION_SOURCE_TYPE_FIELD },
                { header: 'Nombre u objeto', key: constants.PUBLICATION_ABSTRACT_FIELD },
                { header: 'Directores', key: constants.PUBLICATION_AUTHORS_FIELD },
                { header: 'Valor', key: constants.PUBLICATION_CITATION_FIELD },
                { header: 'Idioma', key: constants.PUBLICATION_LANGUAGE_FIELD },
                { header: 'Fecha inicio', key: constants.PUBLICATION_DATE_FIELD },
                { header: 'Fecha fin', key: constants.PUBLICATION_DATE_END_FIELD },
            ];
            worksheet.addRows(value);
            const headerRow = worksheet.getRow(1);
            headerRow.eachCell((cell, colNumber) => {
                if (cell.value) {
                    cell.font = { bold: true };
                    cell.border = { bottom: { style: 'thin' } };
                }
            });
            workbook.xlsx.writeBuffer().then(buffer => {
                const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = [hierarchy.hierarchy.hierarchy[1], ' - ', formatDateLarge(new Date()), '.xlsx'].join('');
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(url);
            });
        }
    };

    const handleRoute = (name) => {
        setMetricsDefault();
        let route = getParams(dependence, name);
        route = [route, 'resource', getParamsRouteString(params.resource)].join('/');
        navigate(route, { state: { key: 'hierarchy' } });
    };

    const handleCurator = (_id, deleted) => {
        setMetricsDefault();
        const isDeleted = deleted ? '/deleted/Publicación eliminada' : '';
        navigate(`/login/Inicio de sesión/curator/Curaduria${isDeleted}/publication/${_id}`, { state: { key: 'hierarchy' } });
    };

    useEffect(() => {
        const optionsAuthor = getOptionsAuthor(options.metrics.author.concat([hierarchy.metrics.author] || []), dependence);
        setProductList(prevState => ({
            ...prevState,
            authorOptionsInit: optionsAuthor.authorOption,
            authorOptions: optionsAuthor.authorOptions,
            authorOption: optionsAuthor.authorOption
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options.metrics.author]);

    useEffect(() => {
        const typeOptions = options.metrics.type.sort();
        setProductList(prevState => ({
            ...prevState,
            typeOptions: typeOptions,
            typeOption: typeOptions
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options.metrics.type]);

    useEffect(() => {
        if (options.author.length > 0) {
            const value = options.author;
            setProductList(prevState => ({ ...prevState, authorOption: value, page: 0 }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options.author]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
                <FormControl
                    fullWidth
                    sx={{ mt: 2 }}
                    variant='outlined'
                >
                    <InputLabel htmlFor='contentSearch'>
                        {t('components.card.cardProductsList.searchContent')}
                    </InputLabel>
                    <OutlinedInput
                        id='contentSearch'
                        label={t('components.card.cardProductsList.searchContent')}
                        value={productList.contentSearch}
                        onChange={(event) => setProductList(prevState => ({ ...prevState, contentSearch: event.target.value }))}
                        onKeyUp={(event) => event.code === 'Enter' ? handleSearch(event.target.value) : null}
                        endAdornment={
                            <InputAdornment position='end'>
                                <IconButton
                                    onClick={() => handleSearch(productList.contentSearch)}
                                    edge='end'
                                >
                                    <SearchTwoTone />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <Box
                    sx={{
                        mt: 2,
                        ml: 0.08,
                        borderRadius: 1,
                        border: '1px solid #C0C0C0',
                        width: '100%'
                    }}
                >
                    <Autocomplete
                        freeSolo
                        multiple
                        options={[]}
                        limitTags={2}
                        value={productList.authorOption}
                        onChange={(event, value) => handleAuthor(event, value)}
                        onKeyUp={(event) => setProductList(prevState => ({ ...prevState, authorSearch: event.target.value }))}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant='standard'
                                label={activeDependence === 'Investigación' ? t('components.card.cardProductsList.authorTrue') : t('components.card.cardProductsList.authorFalse')}
                                placeholder={activeDependence === 'Investigación' ? t('components.card.cardProductsList.authorTrue') : t('components.card.cardProductsList.authorFalse')}
                            />
                        )}
                        sx={{ pr: 1, pl: 1, mb: 1 }}
                    />
                    <TreeSearch
                        height={200}
                        dataTree={productList.authorOptions}
                        params={params}
                        search={productList.authorSearch}
                        handleClick={(value) => handleAuthor({ type: 'tree' }, value)}
                    />
                </Box>
                <Autocomplete
                    id='type'
                    value={productList.typeOption}
                    onChange={handleType}
                    options={productList.typeOptions}
                    multiple
                    limitTags={2}
                    filterSelectedOptions={true}
                    getOptionLabel={(label) => t(`components.graphics.${label}`)}
                    sx={{ mt: 2 }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={activeDependence === 'Investigación' ? t('components.card.cardProductsList.typeTrue') : t('components.card.cardProductsList.typeFalse')}
                        />
                    )}
                />
                <Grid
                    container
                    spacing={1}
                    sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Grid item xs={10}>
                        <FormControl sx={{ mt: 2 }} fullWidth>
                            <InputLabel id='order'>
                                {t('components.card.cardProductsList.order')}
                            </InputLabel>
                            <Select
                                labelId='order'
                                value={productList.order.type}
                                label={t('components.card.cardProductsList.order')}
                                onChange={handleOrderType}
                            >
                                {options.order.fields.includes('relevance') && <MenuItem value='relevance'>{t('components.card.cardProductsList.orderRelevance')}</MenuItem>}
                                {options.order.fields.includes('date') && <MenuItem value='date'>{t('components.card.cardProductsList.orderDate')}</MenuItem>}
                                {options.order.fields.includes('citations') && <MenuItem value='citations'>{t('components.card.cardProductsList.orderCitations')}</MenuItem>}
                                {options.order.fields.includes('value') && <MenuItem value='value'>{t('components.card.cardProductsList.orderValue')}</MenuItem>}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <Tooltip title={productList.order.variant === 'asc' ? t('components.card.cardProductsList.orderAsc') : t('components.card.cardProductsList.orderDsc')}>
                            <IconButton onClick={() => handleOrderVariant(productList.order.variant === 'asc' ? 'dsc' : 'asc')} sx={{ mt: 1.85 }}>
                                <Avatar sx={{ bgcolor: theme => theme.palette.primary.main }}>
                                    {productList.order.variant === 'asc' ? <ArrowUpwardTwoTone /> : <ArrowDownwardTwoTone />}
                                </Avatar>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Button
                    fullWidth
                    startIcon={<DownloadForOfflineTwoTone />}
                    onClick={() => handleDialog()}
                    variant='contained'
                    sx={{ mt: 2 }}
                >
                    {t('components.card.cardProductsList.download')}
                </Button>
            </Grid>
            <Grid item xs={12} sm={8}>
                {data.length === 0 ? (
                    <CardEmpty />
                ) : (
                    <Box sx={{ m: 2 }}>
                        <TablePagination
                            component='div'
                            count={options.total}
                            page={productList.page}
                            onPageChange={handlePage}
                            rowsPerPage={productList.rowsPage}
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            onRowsPerPageChange={handleRowsPage}
                            labelRowsPerPage={t('components.card.cardProductsList.labelRows')}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('components.card.cardProductsList.labelRowsOf')} ${count}`}
                            sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}
                        />
                        <Scrollbar sx={{ maxHeight: 500 }}>
                            {sortedArray(
                                data,
                                constants.ORDER_TYPE[productList.order.type],
                                productList.order.variant,
                                productList.order.type === 'date' ? (date) => new Date(date) : (float) => parseFloat(float)
                            ).map(item => {
                                return (
                                    <Card key={item._id} sx={{ width: '100%', mb: 2 }}>
                                        <CardHeader
                                            title={
                                                <Box>
                                                    <Typography variant='h5'>
                                                        <MathText
                                                            text={formatText(
                                                                settings.language,
                                                                item[constants.PUBLICATION_TITLE_FIELD],
                                                                item[constants.PUBLICATION_SPANISH_TITLE_FIELD],
                                                                item[constants.PUBLICATION_ENGLISH_TITLE_FIELD],
                                                                t('components.card.cardProductsList.title')
                                                            )}
                                                        />
                                                    </Typography>
                                                </Box>
                                            }
                                            subheader={
                                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={0.5}>
                                                    <Tooltip
                                                        title={
                                                            activeDependence === 'Investigación'
                                                                ? t('components.card.cardProductsList.typeTrue')
                                                                : t('components.card.cardProductsList.typeFalse')
                                                        }
                                                    >
                                                        <Chip
                                                            variant='outlined'
                                                            label={
                                                                <Typography variant='body1' color='text.secondary'>
                                                                    {t(`components.graphics.${item[constants.PUBLICATION_SOURCE_TYPE_FIELD]}`)}
                                                                </Typography>
                                                            }
                                                        />
                                                    </Tooltip>
                                                    <Tooltip
                                                        title={
                                                            activeDependence === 'Investigación'
                                                                ? t('components.card.cardProductsList.dateTrue')
                                                                : t('components.card.cardProductsList.dateFalse')
                                                        }
                                                    >
                                                        <Chip
                                                            variant='outlined'
                                                            label={
                                                                <Typography variant='body1' color='text.secondary'>
                                                                    <CalendarMonthTwoTone fontSize='small' sx={{ mr: 0.25 }} />
                                                                    {
                                                                        activeDependence === 'Investigación'
                                                                            ? formatDate(item[constants.PUBLICATION_DATE_FIELD])
                                                                            : formatDateExt(item[constants.PUBLICATION_DATE_FIELD], item[constants.PUBLICATION_DATE_END_FIELD])
                                                                    }
                                                                </Typography>
                                                            }
                                                        />
                                                    </Tooltip>
                                                    <Tooltip
                                                        title={
                                                            activeDependence === 'Investigación'
                                                                ? formatCapitalize(t('components.card.cardProductsList.orderCitationsTrue'))
                                                                : item.variant === 'ActivitiesCol'
                                                                    ? t('components.card.cardProductsList.orderCitationsFalseVariant')
                                                                    : formatCapitalize(t('components.card.cardProductsList.orderCitationsFalse'))
                                                        }
                                                    >
                                                        <Chip
                                                            variant='outlined'
                                                            label={
                                                                <Typography variant='body1' color='text.secondary'>
                                                                    {
                                                                        activeDependence === 'Investigación'
                                                                            ? <FormatQuoteTwoTone fontSize='small' sx={{ mr: 0.25 }} />
                                                                            : item.variant === 'ActivitiesCol'
                                                                                ? <Diversity3TwoTone fontSize='small' sx={{ mr: 0.25 }} />
                                                                                : <PaidTwoTone fontSize='small' sx={{ mr: 0.25 }} />
                                                                    }
                                                                    {
                                                                        activeDependence === 'Investigación'
                                                                            ? item[constants.PUBLICATION_CITATION_FIELD] || 0
                                                                            : item.variant === 'ActivitiesCol'
                                                                                ? item[constants.PUBLICATION_CITATION_FIELD] || 0
                                                                                : formatCurrency(item[constants.PUBLICATION_CITATION_FIELD] || 0)
                                                                    }
                                                                </Typography>
                                                            }
                                                        />
                                                    </Tooltip>
                                                    {options.order.fields.includes('relevance') && (
                                                        <Tooltip title={t('components.card.cardProductsList.relevance')}>
                                                            <Chip
                                                                variant='outlined'
                                                                label={
                                                                    <Typography variant='body1' color='text.secondary'>
                                                                        <MonitorHeartTwoTone fontSize='small' sx={{ mr: 0.25 }} /> {formatNumberFloat(item.relevance)}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </Tooltip>
                                                    )}
                                                    <Tooltip
                                                        title={
                                                            activeDependence === 'Investigación'
                                                                ? t('components.card.cardProductsList.languageTrue')
                                                                : item.variant === 'ActivitiesCol'
                                                                    ? t('components.card.cardProductsList.languageFalseVariant')
                                                                    : t('components.card.cardProductsList.languageFalse')
                                                        }
                                                    >
                                                        <Chip
                                                            variant='outlined'
                                                            label={
                                                                <Typography variant='body1' color='text.secondary'>
                                                                    {
                                                                        activeDependence === 'Investigación'
                                                                            ? <LanguageTwoTone fontSize='small' sx={{ mr: 0.25 }} />
                                                                            : item.variant === 'ActivitiesCol'
                                                                                ? <HourglassTopTwoTone fontSize='small' sx={{ mr: 0.25 }} />
                                                                                : <LanguageTwoTone fontSize='small' sx={{ mr: 0.25 }} />
                                                                    }
                                                                    {
                                                                        activeDependence === 'Investigación'
                                                                            ? constants[settings.language === 'es' ? 'LANGUAGE_ES' : 'LANGUAGE_EN'][item[constants.PUBLICATION_LANGUAGE_FIELD]]
                                                                            : item.variant === 'ActivitiesCol'
                                                                                ? item[constants.intensity] || 0
                                                                                : constants.LANGUAGE[item[constants.PUBLICATION_LANGUAGE_FIELD]]
                                                                    }
                                                                </Typography>
                                                            }
                                                        />
                                                    </Tooltip>
                                                    {item.corrections && (
                                                        <Tooltip title={t('components.card.cardProductsList.correctionsApplied')}>
                                                            <Chip
                                                                color='primary'
                                                                label={
                                                                    <Typography variant='body1'>
                                                                        <BorderColorTwoTone fontSize='small' sx={{ mr: 0.25 }} /> {item.corrections.filter(item => item.state).length}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </Tooltip>
                                                    )}
                                                    {item.corrections && (
                                                        <Tooltip title={t('components.card.cardProductsList.correctionsPending')}>
                                                            <Chip
                                                                color='primary'
                                                                label={
                                                                    <Typography variant='body1'>
                                                                        <EditOffTwoTone fontSize='small' sx={{ mr: 0.25 }} /> {item.corrections.filter(item => !item.state).length}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </Tooltip>
                                                    )}
                                                </Stack>
                                            }
                                            action={
                                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={0.5}>
                                                    {(item[constants.PUBLICATION_URL_FIELD]?.length || 0) !== 0 && (
                                                        [item[constants.PUBLICATION_URL_FIELD].find(url => url.includes('dx.doi.org/')) || item[constants.PUBLICATION_URL_FIELD][0]].map((item, index) => (
                                                            <Tooltip key={index} title={t('components.card.cardProductsList.linkProduct')}>
                                                                <IconButton href={item} target='_blank' rel='noreferrer' onClick={() => handleUrl(item)}>
                                                                    <Avatar sx={{ bgcolor: theme => theme.palette.primary.main }}>
                                                                        <LaunchTwoTone />
                                                                    </Avatar>
                                                                </IconButton>
                                                            </Tooltip>
                                                        ))
                                                    )}
                                                    {options.curator.edit && (
                                                        <Tooltip title={options.curator.deleted ? t('components.card.cardProductsList.curatorTrue') : t('components.card.cardProductsList.curatorFalse')}>
                                                            <IconButton
                                                                onClick={() => handleCurator(item[constants.PUBLICATION_ID_FIELD], options.curator.deleted)}
                                                            >
                                                                <Avatar sx={{ bgcolor: theme => theme.palette.info.main }}>
                                                                    <BorderColorTwoTone />
                                                                </Avatar>
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                </Stack>
                                            }
                                        />
                                        <CardContent>
                                            <CardAuthorList
                                                item={item}
                                                params={params}
                                                handleRoute={handleRoute}
                                                activeDependence={activeDependence}
                                            />
                                            {activeDependence === 'Extensión' && (
                                                <Typography variant='body1' color='text.secondary'>
                                                    <Typography variant='bold' component='label' sx={{ mr: 0.4 }}>
                                                        Uab:
                                                    </Typography>
                                                    {item[constants.DEPENDENCE_UAB_FIELD]}
                                                </Typography>
                                            )}
                                            <Typography variant='body1' color='text.secondary'>
                                                <Typography variant='bold' component='label' sx={{ mr: 0.4 }}>
                                                    {
                                                        activeDependence === 'Investigación'
                                                            ? t('components.card.cardProductsList.abstractTrue') + ':'
                                                            : item.variant === 'Projects'
                                                                ? t('components.card.cardProductsList.abstractFalseVariant') + ':'
                                                                : t('components.card.cardProductsList.abstractFalse') + ':'
                                                    }
                                                </Typography>
                                                <label className='text-container'>
                                                    <MathText
                                                        text={formatText(
                                                            settings.language,
                                                            item[constants.PUBLICATION_ABSTRACT_FIELD],
                                                            item[constants.PUBLICATION_SPANISH_ABSTRACT_FIELD],
                                                            item[constants.PUBLICATION_ENGLISH_ABSTRACT_FIELD],
                                                            t('components.card.cardProductsList.abstractTrue')
                                                        )}
                                                    />
                                                </label>
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                )
                            })}
                        </Scrollbar>
                        <TablePagination
                            component='div'
                            count={options.total}
                            page={productList.page}
                            onPageChange={handlePage}
                            rowsPerPage={productList.rowsPage}
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            onRowsPerPageChange={handleRowsPage}
                            labelRowsPerPage={t('components.card.cardProductsList.labelRows')}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('components.card.cardProductsList.labelRowsOf')} ${count}`}
                            sx={{ borderBottom: 1, borderColor: 'divider', mt: 3 }}
                        />
                    </Box>
                )}
            </Grid>
            <Dialog
                _id='dialog_productList'
                open={productList.dialog}
                title='¿Desea descargar la lista completa de productos?'
                width='md'
                handleClose={handleDialog}
                handleBack={{
                    active: true,
                    label: 'Aplicar filtros',
                    function: () => handleDownload('filter')
                }}
                handleNext={{
                    active: true,
                    label: 'Descargar todo',
                    function: () => handleDownload('all')
                }}
            >
                Tenga en cuenta que el componente permite visualizar un máximo de {productList.rowsPage} productos de un total de {options.total}. Si decide seleccionar la opción <b>Aplicar filtros</b>, la descarga se limitará únicamente a los productos que están visibles en pantalla. En caso de seleccionar la opción de <b>Descargar todo</b>, obtendrá la lista completa de productos correspondientes a la dependencia o autor que está consultando.
            </Dialog>
        </Grid>
    );
};
CardProductsList.propTypes = {
    data: PropTypes.array,
    params: PropTypes.object,
    hierarchy: PropTypes.object,
    options: PropTypes.object,
    handleFilter: PropTypes.func
};
CardProductsList.defaultProps = {
    data: [],
    params: {},
    hierarchy: {},
    options: {},
    handleFilter: () => { }
};
const mapStateToProps = (state) => ({
    dependence: state.dependence,
    settings: state.settings
});
const mapDispatchToProps = (dispatch) => ({
    setMetricsDefault() {
        dispatch({
            type: 'SET_METRICS_DEFAULT'
        });
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(CardProductsList);