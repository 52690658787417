import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReactJson from 'react-json-view';
import ExcelJS from 'exceljs';
import qs from 'qs';
import { Box, Paper, Stack, InputAdornment, TextField, IconButton, Grid, Autocomplete, SpeedDial, SpeedDialIcon, SpeedDialAction, Typography, Badge, useMediaQuery, Button, Container, Avatar } from '@mui/material';
import { LinkTwoTone, FileCopy, CloudDownloadTwoTone, ArrowCircleLeftTwoTone } from '@mui/icons-material';
import AppBar from '../../../components/appbar/Appbar';
import Drawer from '../../../components/Drawer';
import Tooltip from '../../../components/Tooltip';
import { formatOptionsDependences, formatMessage } from '../../../../utils/utils';
import { requestGet, requestPost } from '../../../../utils/request';
import { URL_API_INV } from '../../../../constants';

function Reports(props) {
    const { reports, dependence, investigation, settings, setSettings, setReports } = props;

    const [report, setReport] = useState({
        compuerta: true,
        tema: ''
    });
    const { t } = useTranslation();
    const params = useParams();
    const isXs = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const handleRequest = (options, multiple) => {
        setSettings({ backdrop: formatMessage(true, '', t('reports.consultingInfoSearch')) });
        let paramsOptions = {};
        let paramsRequest = reports.params;
        options.forEach(option => {
            let type = option?.type;
            let value = option?.value;
            if (type && value) {
                if (multiple) {
                    if (value.length === 0) {
                        delete paramsRequest[type];
                    } else {
                        paramsOptions[type] = [];
                        paramsRequest[type] = [];
                        value.forEach(item => {
                            paramsOptions[type].push(item.value);
                            paramsRequest[type].push(item.value);
                        });
                    }
                } else {
                    paramsOptions[type] = value;
                    paramsRequest[type] = value;
                }
            } else if (type && (value === undefined || value === '')) {
                delete paramsRequest[type];
                if (type === 'tema') {
                    delete paramsRequest['compuerta'];
                }
            }
        });
        const queryParams = qs.stringify(paramsRequest).replaceAll('%5B', '[').replaceAll('%5D', ']');
        const url = `${reports.report.url}${queryParams ? `?${queryParams}` : ''}`;
        const hierarchy = {
            ...investigation.hierarchy,
            hierarchy: {
                ...investigation.hierarchy.hierarchy,
                dependence: [false, 'Reportes'],
                hierarchy: ['Reportes', reports.report.subLabel, 'report'],
            },
            resource: reports.report.label,
            subResource: 'Filtros de jerarquía y tiempo',
            action: {
                type: 'Cambios en filtros de jerarquía y tiempo',
                value: JSON.stringify(paramsRequest)
            },
            params: params,
        };
        Promise.all([
            requestGet(reports.report.url, paramsRequest, true),
            requestPost(`${URL_API_INV}/usability/${queryParams ? 'subresource' : 'resource'}`, { record: hierarchy })
        ])
            .then(res => {
                setReports({
                    url: url,
                    params: paramsRequest,
                    options: { ...reports.options, ...paramsOptions },
                    response: res[0].data,
                });
                setSettings({ backdrop: formatMessage(false, '', '') });
            })
            .catch(err => {
                setSettings({
                    backdrop: formatMessage(false, '', ''),
                    snackbar: formatMessage(true, 'error', err),
                });
            });
    };

    const handleCopy = async (value, message) => {
        const aux = document.createElement('input');
        aux.setAttribute('value', value);
        document.body.appendChild(aux);
        aux.select();
        try {
            await navigator.clipboard.writeText(value);
        } finally {
            document.body.removeChild(aux);
        }
        handleUsability('Copiar información al portapapeles', message, value);
    };

    const handleJSON = (value, message) => {
        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(value)}`;
        const link = document.createElement('a');
        link.href = jsonString;
        link.download = reports.report.label + '.json';
        link.click();
        handleUsability('Descargar información', message, value);
    };

    const handleExcel = (value, message) => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Reporte');
        const headers = Object.keys(value[0]);
        worksheet.addRow(headers);
        value.forEach(data => {
            const row = Object.values(data);
            worksheet.addRow(row);
        });
        workbook.xlsx.writeBuffer().then(buffer => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = reports.report.label + '.xlsx';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        });
        let newValue = value;
        if (Array.isArray(newValue)) {
            newValue = [...value];
            newValue.forEach(item => {
                if ('publications' in item) {
                    delete item['publications'];
                }
            });
        }
        handleUsability('Descargar información', message, newValue);
    };

    const handleUsability = (subResource, message, value) => {
        const hierarchy = {
            ...investigation.hierarchy,
            hierarchy: {
                ...investigation.hierarchy.hierarchy,
                dependence: [false, 'Reportes'],
                hierarchy: ['Reportes', reports.report.subLabel, 'report'],
            },
            resource: reports.report.label,
            subResource: subResource,
            action: { type: message, value: value },
            params: params,
        };
        requestPost(`${URL_API_INV}/usability/subresource`, { record: hierarchy });
    };

    useEffect(() => {
        if (reports.report.id) {
            handleRequest([{}], false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reports.report]);

    useEffect(() => {
        if (investigation.hierarchy.stepHome) {
            const hierarchy = {
                ...investigation.hierarchy,
                hierarchy: {
                    ...investigation.hierarchy.hierarchy,
                    dependence: [true, 'Reportes'],
                    hierarchy: ['Reportes', 'Panel inicial', 'report'],
                },
                params: params,
            };
            requestPost(`${URL_API_INV}/usability/hierarchy`, { record: hierarchy });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [investigation.hierarchy.stepHome]);

    return (
        <Box >
            <Drawer view='reports' data={{}} />
            <Box
                component='main'
                sx={{
                    width: { sm: `calc(100% - ${settings.openDrawer ? settings.widthDrawer : 0}px)` },
                    ml: { sm: `${settings.openDrawer ? settings.widthDrawer : 0}px` }
                }}
            >
                <AppBar hierarchy='general' params={params} value={t('header.reports')} />
                <Container sx={{ mt: 3, mb: 3 }}>
                    <Stack direction='column' sx={{ m: 3 }} spacing={3}>
                        <Button
                            variant='contained'
                            onClick={() => setSettings({ openDrawer: true })}
                            sx={{ display: isXs ? 'block' : 'none' }}
                        >
                            {t('reports.buttonPanel')}
                        </Button>

                        {reports.url === '' ? (
                            <Paper elevation={4} sx={{ p: 3 }} onClick={() => isXs ? setSettings({ openDrawer: true }) : null}>
                                <Typography variant='h4'>
                                    <ArrowCircleLeftTwoTone color='primary' sx={{ width: 30, height: 30, mr: 1 }} />
                                    {isXs ? t('reports.infoPanelTrue') : t('reports.infoPanelFalse')}
                                </Typography>
                            </Paper>
                        ) : (
                            <Paper elevation={4} sx={{ p: 3 }}>
                                <Grid
                                    container
                                    spacing={3}
                                    justifyContent='center'
                                    alignItems='center'
                                >
                                    <Grid item xs={11}>
                                        <TextField
                                            disabled
                                            fullWidth
                                            label={t('reports.requestUrl')}
                                            value={reports.url}
                                            variant='filled'
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position='start'>
                                                        <LinkTwoTone />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Tooltip title={t('reports.copyClipboardUrl')}>
                                            <IconButton onClick={() => handleCopy(reports.url, t('reports.copyClipboardUrl', { lng: 'es' }))}>
                                                <FileCopy fontSize='40' />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3} sx={{ mt: 1 }}>
                                    <Grid
                                        item xs={11} md={5}
                                        sx={{ display: reports.report?.disabled?.tema ? 'inline' : 'none' }}
                                    >
                                        <TextField
                                            fullWidth
                                            value={report.tema}
                                            onChange={(event) => setReport({ ...report, tema: event.target.value })}
                                            onKeyUp={(event) => event.code === 'Enter' ? handleRequest([{ type: 'compuerta', value: report.compuerta }, { type: 'tema', value: report.tema }], false) : null}
                                            label={t('reports.topic')}
                                        />
                                    </Grid>
                                    <Grid
                                        item xs={1}
                                        sx={{ display: reports.report?.disabled?.tema ? 'inline' : 'none' }}
                                    >
                                        <Tooltip
                                            title={
                                                [
                                                    t('components.search.SearchTopics.gateInfo'), report.compuerta ? 'AND' : 'OR',
                                                    t('components.search.SearchTopics.gateTo'), report.compuerta ? 'OR' : 'AND'
                                                ].join(' ')
                                            }
                                        >
                                            <IconButton
                                                sx={{
                                                    p: '10px',
                                                    '&:focus': {
                                                        outline: 'transparent',
                                                    },
                                                }}
                                                onClick={(event) => setReport({ ...report, compuerta: !report.compuerta })}
                                            >
                                                <Avatar
                                                    sx={{
                                                        width: 24, height: 24, fontSize: 10,
                                                        bgcolor: theme => theme.palette.info.main,
                                                        '&:focus': {
                                                            outline: 'transparent',
                                                        },
                                                    }}
                                                >
                                                    {report.compuerta ? 'AND' : 'OR'}
                                                </Avatar>
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid
                                        item xs={12} md={6}
                                        sx={{ display: reports.report?.disabled?.institucion ? 'inline' : 'none' }}
                                    >
                                        <Autocomplete
                                            fullWidth
                                            value={reports.options.institucion}
                                            isOptionEqualToValue={(option, value) => option.value === value}
                                            options={formatOptionsDependences(reports.params, dependence, 'institution').institution}
                                            onChange={(event, value) => handleRequest([{ type: 'institucion', value: value?.value }], false)}
                                            renderInput={(params) => (
                                                <TextField {...params} label={t('reports.institution')} />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item xs={12} md={6}
                                        sx={{ display: reports.report?.disabled?.sede ? 'inline' : 'none' }}
                                    >
                                        <Autocomplete
                                            fullWidth
                                            value={reports.options.sede}
                                            isOptionEqualToValue={(option, value) => option.value === value}
                                            options={formatOptionsDependences(reports.params, dependence, 'campus').campus}
                                            onChange={(event, value) => handleRequest([{ type: 'sede', value: value?.value }], false)}
                                            renderInput={(params) => (
                                                <TextField {...params} label={t('reports.campus')} />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item xs={12} md={6}
                                        sx={{ display: reports.report?.disabled?.facultad ? 'inline' : 'none' }}
                                    >
                                        <Autocomplete
                                            fullWidth
                                            value={reports.options.facultad}
                                            isOptionEqualToValue={(option, value) => option.value === value}
                                            options={formatOptionsDependences(reports.params, dependence, 'faculty').faculty}
                                            onChange={(event, value) => handleRequest([{ type: 'facultad', value: value?.value }], false)}
                                            renderInput={(params) => (
                                                <TextField {...params} label={t('reports.faculty')} />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item xs={12} md={6}
                                        sx={{ display: reports.report?.disabled?.departamento ? 'inline' : 'none' }}
                                    >
                                        <Autocomplete
                                            fullWidth
                                            value={reports.options.departamento}
                                            isOptionEqualToValue={(option, value) => option.value === value}
                                            options={formatOptionsDependences(reports.params, dependence, 'uab').uab}
                                            onChange={(event, value) => handleRequest([{ type: 'departamento', value: value?.value }], false)}
                                            renderInput={(params) => (
                                                <TextField {...params} label={t('reports.uab')} />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item xs={12} md={6}
                                        sx={{ display: reports.report?.disabled?._id ? 'inline' : 'none' }}
                                    >
                                        <Autocomplete
                                            fullWidth
                                            multiple={true}
                                            limitTags={2}
                                            value={reports.options._id}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            options={formatOptionsDependences(reports.params, dependence, 'author').identifier}
                                            onChange={(event, value) => handleRequest([{ type: '_id', value: value }], true)}
                                            renderInput={(params) => (
                                                <TextField {...params} label={t('reports.identifier')} />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item xs={12} md={6}
                                        sx={{ display: reports.report?.disabled?.correo ? 'inline' : 'none' }}
                                    >
                                        <Autocomplete
                                            fullWidth
                                            multiple={true}
                                            limitTags={2}
                                            value={reports.options.correo}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            options={formatOptionsDependences(reports.params, dependence, 'author').email}
                                            onChange={(event, value) => handleRequest([{ type: 'correo', value: value }], true)}
                                            renderInput={(params) => (
                                                <TextField {...params} label={t('reports.email')} />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item xs={12} md={6}
                                        sx={{ display: reports.report?.disabled?.genero ? 'inline' : 'none' }}
                                    >
                                        <Autocomplete
                                            fullWidth
                                            value={reports.options.genero}
                                            isOptionEqualToValue={(option, value) => option.value === value}
                                            options={formatOptionsDependences(reports.params, dependence, null).gender}
                                            onChange={(event, value) => handleRequest([{ type: 'genero', value: value?.value }], false)}
                                            renderInput={(params) => (
                                                <TextField {...params} label={t('reports.gender')} />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item xs={12} md={6}
                                        sx={{ display: reports.report?.disabled?.vinculacion ? 'inline' : 'none' }}
                                    >
                                        <Autocomplete
                                            fullWidth
                                            value={reports.options.vinculacion}
                                            isOptionEqualToValue={(option, value) => option.value === value}
                                            options={formatOptionsDependences(reports.params, dependence, null).bonding}
                                            onChange={(event, value) => handleRequest([{ type: 'vinculacion', value: value?.value }], false)}
                                            renderInput={(params) => (
                                                <TextField {...params} label={t('reports.bonding')} />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Paper
                                    elevation={0}
                                    sx={{
                                        display: reports.url === '' ? 'none' : 'block',
                                        mt: 3,
                                        mb: 3,
                                        position: 'relative',
                                    }}
                                >
                                    <ReactJson
                                        name={false}
                                        theme='google'
                                        collapsed={2}
                                        indentWidth={2}
                                        displayDataTypes={false}
                                        src={reports.response}
                                        style={{ padding: 10 }}
                                    />
                                    <SpeedDial
                                        ariaLabel='SpeedDial'
                                        sx={{ position: 'absolute', top: -165, right: 20 }}
                                        icon={<SpeedDialIcon />}
                                    >
                                        <SpeedDialAction
                                            key={t('reports.copyClipboard')}
                                            icon={<FileCopy />}
                                            tooltipTitle={
                                                <Typography variant='body1'>
                                                    {t('reports.copyClipboard')}
                                                </Typography>
                                            }
                                            onClick={() => handleCopy(JSON.stringify(reports.response), t('reports.copyClipboard', { lng: 'es' }))}
                                        />
                                        <SpeedDialAction
                                            key={t('reports.downloadReportJSON')}
                                            icon={
                                                <Badge badgeContent='Json' color='secondary'>
                                                    <CloudDownloadTwoTone />
                                                </Badge>
                                            }
                                            tooltipTitle={
                                                <Typography variant='body1'>
                                                    {t('reports.downloadReportJSON')}
                                                </Typography>
                                            }
                                            onClick={() => handleJSON(JSON.stringify(reports.response), t('reports.downloadReportJSON', { lng: 'es' }))}
                                        />
                                        <SpeedDialAction
                                            key={t('reports.downloadReportEXCEL')}
                                            icon={
                                                <Badge badgeContent='Excel' color='secondary'>
                                                    <CloudDownloadTwoTone />
                                                </Badge>
                                            }
                                            tooltipTitle={
                                                <Typography variant='body1'>
                                                    {t('reports.downloadReportEXCEL')}
                                                </Typography>
                                            }
                                            onClick={() => handleExcel(reports.response, t('reports.downloadReportEXCEL', { lng: 'es' }))}
                                        />
                                    </SpeedDial>
                                </Paper>
                            </Paper>
                        )}
                    </Stack>
                </Container>
            </Box>
        </Box >
    );
}
const mapStateToProps = (state) => ({
    reports: state.reports,
    dependence: state.dependence,
    investigation: state.investigation,
    settings: state.settings,
});
const mapDispatchToProps = (dispatch) => ({
    setSettings(state) {
        dispatch({
            type: 'SET_SETTINGS',
            state,
        });
    },
    setReports(state) {
        dispatch({
            type: 'SET_REPORTS',
            state,
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(Reports);