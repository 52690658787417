import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as d3 from 'd3';
import * as d3GeoProjection from 'd3-geo-projection';
import WorldMap from '../maps/WorldMap.json';
import { formatCapitalize } from '../../../utils/utils';
import { constants } from '../../../constants';

function ChoroplethWorMap(props) {
    const { infoChart, settings } = props;
    const inputRef = useRef(null);
    const { t } = useTranslation();
    const translate = constants.TRANSLATIONS[settings.language].translation.components.graphics;

    const range = (data) => {
        let range = [];
        const colorMap = ['#e4f0f6', '#c0dcea', '#afd2e4', '#79b5d2', '#60a7ca', '#54a0c6', '#3a88ad', '#347a9c', '#317493', '#295f79', '#25556d', '#1f485d'];
        const values = data.map((item) => item.value);
        const maxValue = Math.max(...values);
        if (maxValue >= 40) {
            const parA = maxValue / 5;
            const parB = parA / 4;
            range = [
                [Math.round((parB * 0) + 0, 1), Math.round(parB * 0, 1), colorMap[0], 'l0'],
                [Math.round((parB * 0) + 1, 1), Math.round(parB * 1, 1), colorMap[1], 'l1'],
                [Math.round((parB * 1) + 1, 1), Math.round(parB * 2, 1), colorMap[2], 'l2'],
                [Math.round((parB * 2) + 1, 1), Math.round(parB * 3, 1), colorMap[3], 'l3'],
                [Math.round((parB * 3) + 1, 1), Math.round(parB * 4, 1), colorMap[4], 'l4'],
                [Math.round((parB * 4) + 1, 1), Math.round(parA * 2, 1), colorMap[5], 'l5'],
                [Math.round((parA * 2) + 1, 1), Math.round(parA * 3, 1), colorMap[6], 'l6'],
                [Math.round((parA * 3) + 1, 1), Math.round(parA * 4, 1), colorMap[7], 'l7'],
                [Math.round((parA * 0) + (parA * 4) + 1, 1), Math.round((parA * 4) + (parB * 1), 1), colorMap[8], 'l8'],
                [Math.round((parA * 4) + (parB * 1) + 1, 1), Math.round((parA * 4) + (parB * 2), 1), colorMap[9], 'l9'],
                [Math.round((parA * 4) + (parB * 2) + 1, 1), Math.round((parA * 4) + (parB * 3), 1), colorMap[10], 'l10'],
                [Math.round((parA * 4) + (parB * 3) + 1, 1), Math.round((parA * 4) + (parB * 4), 1), colorMap[11], 'l11'],
            ];
        } else if (maxValue >= 18) {
            const parA = maxValue / 10;
            range = [
                [Math.round((parA * 0) + 0, 1), Math.round(parA * 0, 1), colorMap[0], 'l0'],
                [Math.round((parA * 0) + 1, 1), Math.round(parA * 2, 1), colorMap[1], 'l1'],
                [Math.round((parA * 2) + 1, 1), Math.round(parA * 3, 1), colorMap[2], 'l2'],
                [Math.round((parA * 3) + 1, 1), Math.round(parA * 4, 1), colorMap[3], 'l3'],
                [Math.round((parA * 4) + 1, 1), Math.round(parA * 5, 1), colorMap[4], 'l4'],
                [Math.round((parA * 5) + 1, 1), Math.round(parA * 6, 1), colorMap[5], 'l5'],
                [Math.round((parA * 6) + 1, 1), Math.round(parA * 7, 1), colorMap[6], 'l6'],
                [Math.round((parA * 7) + 1, 1), Math.round(parA * 8, 1), colorMap[7], 'l7'],
                [Math.round((parA * 8) + 1, 1), Math.round(parA * 9, 1), colorMap[8], 'l8'],
                [Math.round((parA * 9) + 1, 1), Math.round(parA * 10, 1), colorMap[9], 'l9'],
            ];
        } else if (maxValue < 18) {
            range = [[0, 0, colorMap[0], 'l0']];
            let iColor = 1;
            for (let iRange = 1; iRange <= maxValue; iRange++) {
                range.push([iRange, iRange + 1, colorMap[iColor], 'l' + iColor])
                iRange++;
                iColor++;
            }
        }
        return range;
    };

    const drawSvg = ({ inputRef, infoChart }) => {
        const square = 20;
        const container = d3.select(inputRef.current);
        const svg = container
            .append('svg')
            .attr('width', infoChart.width)
            .attr('height', infoChart.height);
        const tooltip = container
            .append('div')
            .attr('class', 'tooltipChoroplethMap')
            .attr('id', 'tooltip')
            .style('opacity', 0);
        // const zoom = d3.zoom()
        //   .scaleExtent([1, 8])
        //   .translateExtent([[0, 0], [infoChart.width, infoChart.height]])
        //   .on('zoom', zoomed);
        // function zoomed() {
        //   svg.selectAll('path').attr('transform', d3.event.transform);
        // }
        const projection = d3GeoProjection.geoRobinson().scale(150);
        const path = d3.geoPath().projection(projection);
        const rangeMap = range(infoChart.data);
        svg
            .append('g')
            .selectAll('path')
            .data(WorldMap.features)
            .enter()
            .append('path')
            .attr('class', 'location')
            .attr('fill', function (d) {
                const result = infoChart.data.filter((obj) => obj.group === d.id);
                if (result[0]) {
                    const color = rangeMap.filter((item) => result[0].value >= item[0] && result[0].value <= item[1]);
                    if (color[0]) {
                        d.properties.legend = color[0][3];
                        return color[0][2];
                    }
                }
                d.properties.legend = rangeMap[0][3];
                return rangeMap[0][2];
            })
            .attr('d', path)
            // .call(zoom)
            .on('mouseover', function (d) {
                d3.selectAll('.location,.square')
                    .transition()
                    .duration(200)
                    .style('opacity', .5)
                    .style('stroke', 'transparent');
                d3.select(this)
                    .transition()
                    .duration(200)
                    .style('opacity', 1)
                    .style('stroke', 'black');
                d3.select('#' + d.target.__data__.properties.legend)
                    .transition()
                    .duration(200)
                    .style('opacity', 1)
                    .style('stroke', 'black');
                tooltip.style('opacity', 0.9);
                tooltip.html(() => {
                    const result = infoChart.data.filter((obj) => obj.group === d.target.__data__.id);
                    if (result[0]) {
                        return `<b>${formatCapitalize(translate[d.target.__data__.id])}</b>: ${result[0].value}`;
                    }
                    return `<b>${formatCapitalize(translate[d.target.__data__.id])}</b>`;
                })
                    .attr('data-value', () => {
                        const result = infoChart.data.filter((obj) => obj.group === d.target.__data__.id);
                        if (result[0]) {
                            return result[0].value;
                        }
                        return 0;
                    })
                    .style('left', `${d.layerX + 10}px`)
                    .style('top', `${d.layerY - 28}px`);
            })
            .on('mouseout', function (d) {
                d3.selectAll('.location,.square')
                    .transition()
                    .duration(200)
                    .style('opacity', 1)
                    .style('stroke', 'transparent');
                tooltip.style('opacity', 0);
            });
        const legend = svg
            .append('g')
            .attr('id', 'legend');
        const legendG = legend
            .selectAll('g.legend')
            .data(rangeMap.map((item) => item))
            .enter();
        legendG
            .append('rect')
            .attr('x', 20)
            .attr('y', function (d, i) { return infoChart.height - (i * square) - 2 * square })
            .attr('width', square)
            .attr('height', square)
            .attr('class', 'square')
            .attr('id', function (d) { return d[3] })
            .style('fill', function (d) {
                return d[2];
            });
        legendG
            .append('text')
            .attr('x', 50)
            .attr('y', function (d, i) { return infoChart.height - (i * square) - square - 6 })
            .text(function (d, i) {
                if (i === 0) return '< ' + parseInt(d[1]);
                if (i === (rangeMap.length - 1)) return parseInt(d[0]) + ' >';
                return parseInt(d[0]) + ' - ' + parseInt(d[1]);
            });
        legend
            .append('text')
            .attr('x', 20)
            .attr('y', 470 - (rangeMap.length * square))
            .text((t('components.graphics.choroplethWorMap.collaborations')))
            .style('font-size', '1.1rem');
    };
    useEffect(() => {
        d3.select(inputRef.current).select('svg').remove();
        drawSvg({ inputRef, infoChart });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [infoChart.data, t]);
    return (
        <div id='graph-container' ref={inputRef} />
    );
}
ChoroplethWorMap.propTypes = {
    infoChart: PropTypes.shape({
        data: PropTypes.array,
        width: PropTypes.number,
        height: PropTypes.number,
    })
};
const mapStateToProps = (state) => ({
    settings: state.settings
});
export default connect(mapStateToProps)(ChoroplethWorMap);