import React from 'react';
import PropTypes from 'prop-types';
import { Column } from '@antv/g2plot';
import ReactG2Plot from 'react-g2plot';
import { configColumnGroup, configColorGender } from '../../../assets/theme/g2Theme';
import { formatCurrency, formatNumber, formatPercent } from '../../../utils/utils';

function ColumnGroup(props) {
    const { infoChart } = props;
    return (
        <ReactG2Plot
            Ctor={Column}
            options={{
                ...{
                    data: infoChart.data,
                    xAxis: { title: { text: infoChart.axis.xAxis } },
                    yAxis: {
                        title: { text: infoChart.axis.yAxis },
                        ...(infoChart.formatValue === 'money' ? {
                            label: {
                                formatter: (v) => {
                                    return new Intl.NumberFormat('es-ES', {
                                        style: 'currency',
                                        currency: 'COP',
                                        notation: 'compact',
                                    }).format(v).replace('COP', '')
                                }
                            },
                        } : {})
                    },
                    ...(infoChart.colorGender ? configColorGender : {}),
                    tooltip: {
                        formatter: (item) => {
                            return {
                                title: item.group,
                                name: item.type,
                                value: infoChart.formatValue === 'money'
                                    ? formatCurrency(item.value)
                                    : infoChart.formatValue === 'number'
                                        ? formatNumber(item.value)
                                        : infoChart.formatValue === 'percent'
                                            ? formatPercent(item.value / 100)
                                            : item.value,
                            };
                        },
                    },
                },
                ...configColumnGroup
            }}
        />
    );
}
ColumnGroup.propTypes = {
    infoChart: PropTypes.shape({
        data: PropTypes.array,
        colorGender: PropTypes.bool,
        axis: PropTypes.object
    })
};
export default ColumnGroup;