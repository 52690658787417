import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Stack, Typography, Avatar, Chip, Grid } from '@mui/material';
import { Person3TwoTone, PersonOutlineTwoTone, PersonAddAlt1TwoTone, PersonRemoveAlt1TwoTone, PersonOffTwoTone, ArrowForwardIosTwoTone } from '@mui/icons-material';
import CardGraphics from '../../../../components/card/CardGraphics';
import DataTable from '../../../../components/DataTable';
import PieHalf from '../../../../components/graphics/PieHalf';
import ColumnStacked from '../../../../components/graphics/ColumnStacked';
import Column from '../../../../components/graphics/Column';
import Tooltip from '../../../../components/Tooltip';
import { requestPost } from '../../../../../utils/request';
import { metricsInv, getParams, getParamsRouteString, formatMessage, objectAssignInv, formatDependencesName } from '../../../../../utils/utils';
import { constants, URL_API_INV } from '../../../../../constants';


function Researchers(props) {
    const { dependence, investigation, settings, setMetricsDefault, setInvestigation, setSettings } = props;
    const [isSearch, setIsSearch] = useState(false);

    const { t } = useTranslation();
    const params = useParams();
    const navigate = useNavigate();
    const activeHierarchy = investigation.hierarchy.hierarchy.hierarchy[2];

    const handleOnChange = (value, state) => {
        const newInvestigation = objectAssignInv(investigation, {
            metrics: { researchers: { [state]: value } },
        });
        newInvestigation.filters.rangeTime.type = true;
        setInvestigation(
            metricsInv(
                [state],
                investigation.metrics.researchers.source,
                investigation.hierarchy,
                newInvestigation,
                settings
            )
        );
    };

    const handleRoute = (name) => {
        setMetricsDefault();
        let route = getParams(dependence, name);
        route = [route, 'resource', getParamsRouteString('products')].join('/');
        navigate(route, { state: { key: 'hierarchy' } });
    };

    const handleRouteFilter = (filter) => {
        const author = [];
        const authors = investigation.metrics.researchers[constants.METRIC_AUTHORS_FIELD].processed;
        authors.forEach(item => {
            if (item[filter.field] === filter.value) {
                author.push({
                    label: item[constants.AUTHOR_NAME_FIELD],
                    value: item[constants.AUTHOR_ID_FIELD]
                });
            }
        });
        const route = `/search/research/query/${params.query}/resource/productsList`;
        navigate(route, { state: { key: 'hierarchy', author: author } });
    };

    const columnsResearchers = [
        {
            field: constants.AUTHOR_IMAGE_FIELD,
            headerName: t('dependence.investigation.researchersMain.image'),
            width: 50,
            hide: true,
            renderCell: (params) => (
                <Avatar
                    sx={{
                        width: 30,
                        height: 30,
                        bgcolor: theme =>
                            theme.palette[params.row[constants.AUTHOR_GENDER_FIELD] === 'F'
                                ? 'female'
                                : params.row[constants.AUTHOR_GENDER_FIELD] === 'M'
                                    ? 'male'
                                    : 'info'].main,
                    }}
                >
                    {params.row[constants.AUTHOR_IMAGE_FIELD] ? (
                        <Box
                            component='img'
                            width='100%'
                            height='100%'
                            src={
                                params.row[constants.AUTHOR_IMAGE_FIELD].includes('http')
                                    ? params.row[constants.AUTHOR_IMAGE_FIELD]
                                    : require('../../../../../assets/images/' + params.row[constants.AUTHOR_IMAGE_FIELD])
                            }
                        />
                    ) : (
                        <Box
                            component={
                                params.row[constants.AUTHOR_GENDER_FIELD] === 'F'
                                    ? Person3TwoTone
                                    : PersonOutlineTwoTone
                            }
                        />
                    )}
                </Avatar>
            ),
        },
        {
            field: constants.AUTHOR_NAME_FIELD,
            headerName: t('dependence.investigation.researchersMain.name'),
            flex: 1,
            hide: true,
            renderCell: (params) => (
                <Typography
                    fontSize='inherit'
                    color={theme =>
                        theme.palette[params.row[constants.AUTHOR_GENDER_FIELD] === 'F'
                            ? 'female'
                            : params.row[constants.AUTHOR_GENDER_FIELD] === 'M'
                                ? 'male'
                                : 'info'].main}
                    sx={{
                        '&:hover': {
                            textDecoration: 'underline',
                        },
                        cursor: 'pointer',
                    }}
                    onClick={() => handleRoute(params.row[constants.AUTHOR_NAME_FIELD])}
                >
                    {params.row[constants.AUTHOR_NAME_FIELD]}
                </Typography>
            ),
        },
        {
            field: constants.AUTHOR_CAMPUS_FIELD,
            headerName: t('dependence.investigation.researchersMain.campus'),
            width: 100,
            hide: isSearch,
            renderCell: (params) => (
                <Tooltip placement='left' title={t('dependence.investigation.researchersMain.tooltipFilter')}>
                    <Chip
                        size='small'
                        sx={{ pl: 0.25 }}
                        label={formatDependencesName(params.row[constants.AUTHOR_CAMPUS_FIELD])}
                        onClick={() => handleRouteFilter({field: constants.AUTHOR_CAMPUS_FIELD, value: params.row[constants.AUTHOR_CAMPUS_FIELD]})}
                        onDelete={() => handleRouteFilter({field: constants.AUTHOR_CAMPUS_FIELD, value: params.row[constants.AUTHOR_CAMPUS_FIELD]})}
                        deleteIcon={
                            <ArrowForwardIosTwoTone fontSize='small' />
                        }
                    />
                </Tooltip>
            )
        },
        {
            field: constants.AUTHOR_FACULTY_FIELD,
            headerName: t('dependence.investigation.researchersMain.faculty'),
            flex: 1,
            hide: isSearch,
            renderCell: (params) => (
                <Tooltip placement='left' title={t('dependence.investigation.researchersMain.tooltipFilter')}>
                    <Chip
                        size='small'
                        sx={{ pl: 0.25 }}
                        label={formatDependencesName(params.row[constants.AUTHOR_FACULTY_FIELD])}
                        onClick={() => handleRouteFilter({field: constants.AUTHOR_FACULTY_FIELD, value: params.row[constants.AUTHOR_FACULTY_FIELD]})}
                        onDelete={() => handleRouteFilter({field: constants.AUTHOR_FACULTY_FIELD, value: params.row[constants.AUTHOR_FACULTY_FIELD]})}
                        deleteIcon={
                            <ArrowForwardIosTwoTone fontSize='small' />
                        }
                    />
                </Tooltip>
            )
        },
        {
            field: constants.AUTHOR_UAB_FIELD,
            headerName: t('dependence.investigation.researchersMain.uab'),
            flex: 1,
            hide: isSearch,
            renderCell: (params) => (
                <Tooltip placement='left' title={t('dependence.investigation.researchersMain.tooltipFilter')}>
                    <Chip
                        size='small'
                        sx={{ pl: 0.25 }}
                        label={formatDependencesName(params.row[constants.AUTHOR_UAB_FIELD])}
                        onClick={() => handleRouteFilter({field: constants.AUTHOR_UAB_FIELD, value: params.row[constants.AUTHOR_UAB_FIELD]})}
                        onDelete={() => handleRouteFilter({field: constants.AUTHOR_UAB_FIELD, value: params.row[constants.AUTHOR_UAB_FIELD]})}
                        deleteIcon={
                            <ArrowForwardIosTwoTone fontSize='small' />
                        }
                    />
                </Tooltip>
            )
        },
        {
            field: constants.AUTHOR_STATE_FIELD,
            headerName: t('dependence.investigation.researchersMain.bonding'),
            width: isSearch ? 100 : 130,
            hide: true,
            renderCell: (params) => (
                <Chip
                    size='small'
                    variant='outlined'
                    sx={{ pl: 0.25 }}
                    label={t('components.graphics.' + params.row[constants.AUTHOR_STATE_FIELD])}
                    color={
                        params.row[constants.AUTHOR_STATE_FIELD] === 'Activo'
                            ? 'primary'
                            : params.row[constants.AUTHOR_STATE_FIELD] === 'Retirado'
                                ? 'secondary'
                                : 'dark'
                    }
                    avatar={
                        <Avatar>
                            {
                                params.row[constants.AUTHOR_STATE_FIELD] === 'Activo'
                                    ? <PersonAddAlt1TwoTone fontSize='small' />
                                    : params.row[constants.AUTHOR_STATE_FIELD] === 'Retirado'
                                        ? <PersonRemoveAlt1TwoTone fontSize='small' />
                                        : <PersonOffTwoTone fontSize='small' />
                            }
                        </Avatar>
                    }
                />
            )
        },
        {
            field: constants.METRIC_VALUE_FIELD,
            headerName: t('dependence.investigation.publicationsAmount'),
            width: isSearch ? 100 : 130,
            hide: true,
            type: 'number',
            renderCell: (params) => isSearch ? (
                <Tooltip placement='left' title={t('dependence.investigation.researchersMain.tooltipFilter')}>
                    <Chip
                        size='small'
                        sx={{ pl: 0.25 }}
                        label={params.row[constants.METRIC_VALUE_FIELD]}
                        onClick={() => handleRouteFilter({field: constants.AUTHOR_NAME_FIELD, value: params.row[constants.AUTHOR_NAME_FIELD]})}
                        onDelete={() => handleRouteFilter({field: constants.AUTHOR_NAME_FIELD, value: params.row[constants.AUTHOR_NAME_FIELD]})}
                        deleteIcon={
                            <ArrowForwardIosTwoTone fontSize='small' />
                        }
                    />
                </Tooltip>
            ) : (
                params.row[constants.METRIC_VALUE_FIELD]
            )
        }
    ];

    useEffect(() => {
        const isSearch = 'query' in params;
        setIsSearch(isSearch);

        setSettings({ backdrop: formatMessage(true, '', t('dependence.investigation.consultingInfoResearchers')) });
        const hierarchy = {
            ...investigation.hierarchy,
            resource: 'Investigadores',
        };
        if (investigation.hierarchy.metrics.researchers || investigation.search.search) {
            Promise.all([
                requestPost(`${URL_API_INV}/investigation/researchers_metrics`, {
                    _id: investigation.hierarchy.metrics.researchers,
                    search: investigation.search.search,
                    record: hierarchy
                }),
            ])
                .then(res => {
                    setInvestigation(
                        metricsInv(
                            Object.keys(investigation.metrics.researchers),
                            res[0].data,
                            hierarchy,
                            investigation,
                            settings
                        )
                    );
                    setSettings({ backdrop: formatMessage(false, '', '') });
                })
                .catch(err => {
                    setSettings({
                        backdrop: formatMessage(false, '', ''),
                        snackbar: formatMessage(true, 'error', err),
                    });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [investigation.hierarchy.metrics.researchers, investigation.search.search, settings.language]);

    return (
        <Box>
            <Grid container spacing={3}>
                <Grid item xs={12} md={isSearch ? 12 : 6}>
                    <CardGraphics
                        title={t('dependence.investigation.researchersMain.researcherProfessorsList')}
                        tools={{
                            usability: true,
                            rangeTime: false,
                            gender: false,
                            type: false,
                            accumulate: false,
                            wordCloud: false,
                            download: true,
                            dialog: false,
                            dataTable: true
                        }}
                        infoDialog={{
                            label: '',
                            gif: '',
                            notice: ''
                        }}
                        infoChart={{
                            data: investigation.metrics.researchers[constants.METRIC_AUTHORS_FIELD],
                            onChange: (value) => handleOnChange(value, constants.METRIC_AUTHORS_FIELD),
                            columns: {
                                [constants.AUTHOR_NAME_FIELD]: t('dependence.investigation.researchGroups.name'),
                                [constants.AUTHOR_STATE_FIELD]: t('dependence.investigation.researchGroups.bonding'),
                                [constants.METRIC_VALUE_FIELD]: t('dependence.investigation.publicationsAmount')
                            },
                            formatValue: 'number',
                            sortField: 'value'
                        }}
                    >
                        <DataTable
                            id='_id'
                            rows={investigation.metrics.researchers[constants.METRIC_AUTHORS_FIELD].processed}
                            columns={columnsResearchers.filter(column => column.hide)}
                            sortModel={[{ field: 'value', sort: 'desc' }]}
                            fileName='Lista_investigadores'
                        />
                    </CardGraphics>
                </Grid>
                <Grid item xs={12} md={isSearch ? 12 : 6}>
                    <CardGraphics
                        title={t('dependence.investigation.researchersMain.activeProfessorsPerYearWhoPublish')}
                        tools={{
                            usability: true,
                            rangeTime: true,
                            gender: false,
                            type: false,
                            accumulate: false,
                            wordCloud: false,
                            download: true,
                            dialog: false,
                            dataTable: true
                        }}
                        infoDialog={{
                            label: '',
                            gif: '',
                            notice: ''
                        }}
                        infoChart={{
                            data: investigation.metrics.researchers[constants.METRIC_PERCENTAGE_PUBLISHING_FIELD],
                            onChange: (value) => handleOnChange(value, constants.METRIC_PERCENTAGE_PUBLISHING_FIELD),
                            columns: {
                                axis: t('dependence.investigation.year'),
                                value: t('dependence.investigation.percentage'),
                            },
                            formatValue: 'percent',
                            sortField: 'axis'
                        }}
                    >
                        <Column
                            infoChart={{
                                data: investigation.metrics.researchers[constants.METRIC_PERCENTAGE_PUBLISHING_FIELD].processed,
                                axis: {
                                    xAxis: t('dependence.investigation.year'),
                                    yAxis: t('dependence.investigation.percentage'),
                                },
                                formatValue: 'percent',
                                label: false
                            }}
                        />
                    </CardGraphics>
                </Grid>
            </Grid>
            <Stack
                direction={{ xs: 'column', md: 'row' }}
                spacing={3}
                sx={{
                    mt: 3,
                    display: activeHierarchy === 'author' ? 'none' : 'block'
                }}
            >
                <CardGraphics
                    title={t('dependence.investigation.researchersMain.activeProfessorsPerYear')}
                    tools={{
                        usability: true,
                        rangeTime: true,
                        gender: false,
                        type: false,
                        accumulate: false,
                        wordCloud: false,
                        download: true,
                        dialog: false,
                        dataTable: true
                    }}
                    infoDialog={{
                        label: '',
                        gif: '',
                        notice: ''
                    }}
                    infoChart={{
                        data: investigation.metrics.researchers[constants.METRIC_ACTIVE_FIELD],
                        onChange: (value) => handleOnChange(value, constants.METRIC_ACTIVE_FIELD),
                        columns: {
                            axis: t('dependence.investigation.year'),
                            value: t('dependence.investigation.researchersMain.professorsAmount'),
                        },
                        formatValue: 'number',
                        sortField: 'axis'
                    }}
                >
                    <Column
                        infoChart={{
                            data: investigation.metrics.researchers[constants.METRIC_ACTIVE_FIELD].processed,
                            axis: {
                                xAxis: t('dependence.investigation.year'),
                                yAxis: t('dependence.investigation.researchersMain.professorsAmount')
                            },
                            formatValue: 'number',
                            label: false
                        }}
                    />
                </CardGraphics>
            </Stack>
            <Grid container spacing={3} sx={{ mt: 0.5 }}>
                <Grid item xs={12} md={6}>
                    <CardGraphics
                        title={t('dependence.investigation.researchersMain.genderDistribution')}
                        tools={{
                            usability: true,
                            rangeTime: false,
                            gender: false,
                            type: false,
                            accumulate: false,
                            wordCloud: false,
                            download: true,
                            dialog: false,
                            dataTable: true
                        }}
                        infoDialog={{
                            label: '',
                            gif: '',
                            notice: ''
                        }}
                        infoChart={{
                            data: investigation.metrics.researchers[constants.METRIC_GENDER_FIELD],
                            onChange: (value) => handleOnChange(value, constants.METRIC_GENDER_FIELD),
                            columns: {
                                type: t('dependence.investigation.gender'),
                                value: t('dependence.investigation.researchersMain.professorsAmount'),
                                percent: t('dependence.investigation.percentage'),
                            },
                            formatValue: 'number',
                            sortField: 'percent'
                        }}
                    >
                        <PieHalf
                            infoChart={{
                                data: investigation.metrics.researchers[constants.METRIC_GENDER_FIELD].processed,
                                colorGender: true,
                            }}
                        />
                    </CardGraphics>
                </Grid>
                <Grid item xs={12} md={6}>
                    <CardGraphics
                        title={t('dependence.investigation.researchersMain.amountByAgeGroup')}
                        tools={{
                            usability: true,
                            rangeTime: false,
                            gender: false,
                            type: false,
                            accumulate: false,
                            wordCloud: false,
                            download: true,
                            dialog: false,
                            dataTable: true
                        }}
                        infoDialog={{
                            label: '',
                            gif: '',
                            notice: ''
                        }}
                        infoChart={{
                            data: investigation.metrics.researchers[constants.METRIC_AGE_FIELD],
                            onChange: (value) => handleOnChange(value, constants.METRIC_AGE_FIELD),
                            columns: {
                                axis: t('dependence.investigation.researchersMain.ageGroup'),
                                type: t('dependence.investigation.gender'),
                                value: t('dependence.investigation.researchersMain.professorsAmount')
                            },
                            formatValue: 'number',
                            sortField: 'value'
                        }}
                    >
                        <ColumnStacked
                            infoChart={{
                                data: investigation.metrics.researchers[constants.METRIC_AGE_FIELD].processed,
                                colorGender: true,
                                axis: {
                                    xAxis: t('dependence.investigation.researchersMain.ageGroup'),
                                    yAxis: t('dependence.investigation.researchersMain.professorsAmount'),
                                },
                            }}
                        />
                    </CardGraphics>
                </Grid>
            </Grid>
        </Box>
    );
}
const mapStateToProps = (state) => ({
    dependence: state.dependence,
    investigation: state.investigation,
    settings: state.settings
});
const mapDispatchToProps = (dispatch) => ({
    setMetricsDefault() {
        dispatch({
            type: 'SET_METRICS_DEFAULT',
        });
    },
    setInvestigation(state) {
        dispatch({
            type: 'SET_INVESTIGATION',
            state,
        });
    },
    setSettings(state) {
        dispatch({
            type: 'SET_SETTINGS',
            state,
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(Researchers);